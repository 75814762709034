import { Flex, Side, VStack, chain } from '@revolut/ui-kit'
import { EntityTypes } from '@src/constants/api'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { UnassignedRoadmapInterface } from '@src/interfaces/roadmaps'
import { JiraSearchWidget } from '@src/pages/OnboardingChecklist/Roadmaps/JiraSearch'
import pluralize from 'pluralize'
import React, { useState } from 'react'
import { SideAction } from './SidebarAction'
import { GoalTargetCycleSelector } from '../../common/GoalTargetCycleSelector'

export const LinkRoadmapsForm = ({
  submit,
  initialCycle,
  ownerId,
  pending,
}: {
  submit: (payload: {
    cycle: ReviewCyclesInterface
    epics: UnassignedRoadmapInterface[]
  }) => void
  initialCycle?: ReviewCyclesInterface
  ownerId: number
  pending: boolean
}) => {
  const { entity } = useOrgEntity()
  const [cycle, setCycle] = useState<ReviewCyclesInterface | null>(initialCycle || null)
  const [epics, setEpics] = useState<UnassignedRoadmapInterface[]>()

  const handleAdd = () => {
    if (cycle && epics) {
      submit({ cycle, epics })
    }
  }

  return (
    <VStack space="s-16">
      <GoalTargetCycleSelector
        onSelect={setCycle}
        initialCycle={initialCycle}
        ownerId={ownerId}
        isEmployee={
          entity?.type === EntityTypes.employees || entity?.type === EntityTypes.employee
        }
      />
      <JiraSearchWidget
        disabled={!cycle}
        onSelectionChange={e => setEpics(Object.values(e))}
      />
      <Side.Actions>
        <Flex width="100%" justifyContent="center">
          <SideAction
            pending={pending}
            onClick={handleAdd}
            disabled={epics?.length === 0}
          >
            Add {chain(pluralize('roadmap', epics?.length), epics?.length || 0)}
          </SideAction>
        </Flex>
      </Side.Actions>
    </VStack>
  )
}
