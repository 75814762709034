import React, { useState } from 'react'
import { GoalsPreviewHeader } from './Header'
import { GoalsPreviewLeftSide } from './LeftSide'
import { PageWrapper } from '@src/components/Page/Page'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalPreviewInterface } from '@src/interfaces/goals'
import { captureException } from '@sentry/react'
import { BREAKPOINTS } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import { UpdateTypes } from '@src/interfaces/kpis'
import { useQuery } from '@src/utils/queryParamsHooks'
import get from 'lodash/get'
import set from 'lodash/set'

const getGoalTargetsCycles = (goal: GoalPreviewInterface): ReviewCyclesInterface[] => {
  const uniqueCycleIds: Record<number, never> = {}

  return goal.kpis
    .map(kpi => {
      const target =
        kpi.update_type === UpdateTypes.roadmap ? kpi.target_epics?.[0] : kpi.targets?.[0]

      const cycle = target?.review_cycle

      if (!cycle || get(uniqueCycleIds, cycle.id)) {
        return undefined
      }

      set(uniqueCycleIds, cycle.id, true)
      return cycle as ReviewCyclesInterface
    })
    .filter(Boolean)
    .sort((a, b) => Number(b.offset) - Number(a.offset))
}

export const GoalPreviewPage = () => {
  const { values } = useLapeContext<GoalPreviewInterface>()
  const { query } = useQuery()
  const defaultCycleId = query.cycle__id
  const [availableCycles] = useState(getGoalTargetsCycles(values))

  const initialCycle =
    availableCycles.find(({ offset, id }) =>
      defaultCycleId ? Number(id) === Number(defaultCycleId) : offset === 0,
    ) || availableCycles[0]

  const [cycle, setCycle] = useState<ReviewCyclesInterface>(initialCycle)

  return (
    <PageWrapper>
      <PageBody maxWidth={{ all: BREAKPOINTS.lg, xxl: BREAKPOINTS.xl }}>
        <GoalsPreviewHeader cycle={cycle} />
        {cycle ? (
          <GoalsPreviewLeftSide
            cycle={cycle}
            availableCycles={availableCycles}
            onCycleChanged={id => {
              const newCycle = availableCycles.find(c => c.id === id)!
              setCycle(newCycle)
              if (!newCycle) {
                // should not ever happen
                const meta = {
                  cycleId: id,
                  availableCyclesId: availableCycles.map(c => c.id),
                }
                captureException(`Failed to update cycle in the goal preview page`, {
                  extra: meta,
                })
              }
            }}
          />
        ) : null}
      </PageBody>
    </PageWrapper>
  )
}
