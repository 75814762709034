import React from 'react'
import groupBy from 'lodash/groupBy'
import { Item, VStack, Text, Token, TextButton, Subheader, Cell } from '@revolut/ui-kit'

import SideBar from '@components/SideBar/SideBar'
import { useGetConfigurableBulkUploadFields } from '@src/api/bulkDataImport'
import {
  BulkUploadConfigurableField,
  ConfigurableBulkUploadEntity,
} from '@src/interfaces/bulkDataImport'

type Props = {
  isOpen: boolean
  onClose: () => void
  entity: ConfigurableBulkUploadEntity
  onAddField: (field: BulkUploadConfigurableField) => void
  addedFields: BulkUploadConfigurableField[]
}
export const AddFieldsSidebar = ({
  isOpen,
  onClose,
  entity,
  onAddField,
  addedFields,
}: Props) => {
  const { data: configurableFieldsData = [] } = useGetConfigurableBulkUploadFields(entity)
  const groupedFieldsData: Record<string, BulkUploadConfigurableField[]> = groupBy(
    configurableFieldsData.filter(field => !addedFields.includes(field)),
    field => field.category,
  )

  return (
    <SideBar title="Add fields" isOpen={isOpen} onClose={onClose}>
      <VStack space="s-16">
        {Object.entries(groupedFieldsData).map(([group, fields]) => (
          <React.Fragment key={group}>
            <Subheader variant="nested">
              <Subheader.Title>{group}</Subheader.Title>
            </Subheader>
            <Cell p={0} mt="-s-16">
              <VStack width="100%">
                {fields.map(field => (
                  <Item key={field.name}>
                    <Item.Content>{field.name}</Item.Content>
                    <Item.Side>
                      {field.is_required ? (
                        <Text color={Token.color.greyTone50}>Preset</Text>
                      ) : (
                        <TextButton onClick={() => onAddField(field)}>Add</TextButton>
                      )}
                    </Item.Side>
                  </Item>
                ))}
              </VStack>
            </Cell>
          </React.Fragment>
        ))}
      </VStack>
    </SideBar>
  )
}
