import { LocalStorageKeys } from '@src/store/auth/types'
import { PUBLIC_ROUTES, ROUTES } from './routes'

export enum Environments {
  local = 'local',
  development = 'development',
  staging = 'staging',
  pipeline = 'pipeline',
  developmentSubdomains = 'development-subdomains',
  developmentCommercial = 'development-commercial',
  developmentCommercialRoot = 'development-commercial-root',
  production = 'production',
  productionCommercial = 'production-commercial',
  productionCommercialRoot = 'production-commercial-root',
}

// key should be a regex pattern
const hostnameToEnv = {
  '^people\\.revolut\\.com$': Environments.production,
  '^(www.)?revolutpeople\\.com$': Environments.productionCommercialRoot,
  // <optional subdomain>.revolutpeople.com
  '^(?:[a-zA-Z0-9-]+\\.)?revolutpeople\\.com$': Environments.productionCommercial,
  // <optional subdomain>.pipeline<any number>.revoluters.com
  '^(?:[a-zA-Z0-9-]+\\.)?pipeline[0-9]+\\.revoluters\\.com$': Environments.pipeline,
  '^(www.)?peopleops\\.dev$': Environments.developmentCommercialRoot,
  '^(?:[a-zA-Z0-9-]+\\.)?peopleops\\.dev$': Environments.developmentCommercial,
  '^(?:[a-zA-Z0-9-]+\\.)dev\\.revoluters\\.com$': Environments.developmentSubdomains,
  '^dev\\.revoluters\\.com$': Environments.development,
  '^staging\\.revoluters\\.com$': Environments.staging,
  '^localhost$': Environments.local,
}

const hostToEnv = (host: string) => {
  return Object.entries(hostnameToEnv).find(([pattern]) =>
    new RegExp(pattern).test(host),
  )?.[1]
}

export const env = hostToEnv(window.location.hostname)

export const CORE_INSTANCE_WORKSPACE = 'core'

const devWorkspacesEnabled =
  localStorage.getItem(LocalStorageKeys.ENABLE_DEV_WORKSPACES) === 'True'

export const overrideAPIMapWithTenant = (tenant: string) => {
  // TODO: update routes when testing in `developmentCommercial` is done
  /* We don't have a workspace for "core" instance, use main endpoints */
  if (
    tenant !== CORE_INSTANCE_WORKSPACE &&
    (env === Environments.developmentCommercialRoot ||
      (env === Environments.local && devWorkspacesEnabled))
  ) {
    const apiEndpoint = `https://peopleops.dev/api/${tenant}/`
    const wssEndpoint = `wss://peopleops.dev/${tenant}/ws/v1/user/`

    APIMap[env] = apiEndpoint
    WSMap[env] = wssEndpoint
  }
}

export const APIMap = {
  [Environments.local]: devWorkspacesEnabled
    ? `https://peopleops.dev/api/`
    : 'https://dev.revoluters.com/employee/api/',
  [Environments.development]: 'https://dev.revoluters.com/employee/api/',
  [Environments.developmentSubdomains]: 'https://dev.revoluters.com/employee/api/',
  [Environments.staging]: 'https://staging.revoluters.com/employee/api/',
  [Environments.pipeline]: `https://${window.location.hostname}/employee/api/`,
  [Environments.developmentCommercial]: `https://${window.location.hostname}/api/`,
  [Environments.developmentCommercialRoot]: `https://${window.location.hostname}/api/`,
  [Environments.productionCommercial]: `https://${window.location.hostname}/api/`,
  [Environments.productionCommercialRoot]: `https://${window.location.hostname}/api/`,
  [Environments.production]: 'https://people.revolut.com/employee/api/',
}

export const API_ENDPOINT = () =>
  // @ts-ignore
  localStorage.getItem('api_endpoint') || window.__API_URL__ || APIMap[env]

export const WSMap = {
  [Environments.local]: devWorkspacesEnabled
    ? `wss://peopleops.dev/ws/v1/user/`
    : 'wss://dev.revoluters.com/ws/v1/user/',
  [Environments.development]: 'wss://dev.revoluters.com/ws/v1/user/',
  [Environments.staging]: 'wss://staging.revoluters.com/ws/v1/user/',
  [Environments.pipeline]: `wss://${window.location.hostname}/ws/v1/user/`,
  [Environments.developmentCommercial]: `wss://${window.location.hostname}/ws/v1/user/`,
  [Environments.developmentCommercialRoot]: `wss://${window.location.hostname}/ws/v1/user/`,
  [Environments.productionCommercial]: `wss://${window.location.hostname}/ws/v1/user/`,
  [Environments.productionCommercialRoot]: `wss://${window.location.hostname}/ws/v1/user/`,
  [Environments.production]: 'wss://people.revolut.com/ws/v1/user/',
}

export const WSS_ENDPOINT = () =>
  // @ts-ignore
  localStorage.getItem('wss_endpoint') || window.__WSS_URL__ || WSMap[env]

export const REDIRECT_PARAM = `${document.location.origin}${ROUTES.LOGIN.REDIRECT}`

export const SIGNUP_REDIRECT_PARAM = `${document.location.origin}${ROUTES.SIGNUP.REDIRECT}`

const getGrantPermissionRedirectHost = () => {
  switch (env) {
    case Environments.productionCommercialRoot:
    case Environments.productionCommercial:
      return 'https://revolutpeople.com'

    case Environments.developmentCommercialRoot:
    case Environments.developmentCommercial:
      return 'https://peopleops.dev'

    default:
      return document.location.origin
  }
}
export const GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM = `${getGrantPermissionRedirectHost()}${
  PUBLIC_ROUTES.SUBDOMAIN_PERMISSIONS_REDIRECT
}`

export const API = {
  ACCOUNT: '/account',
  ACCOUNT_CLOSURE: '/accountClosure',
  ACCESS_REQUESTS: '/accessRequests',
  ANNOUNCEMENTS: '/announcements',
  AUDIENCES: '/audiences',
  LOOKER_ACCESS_REQUESTS: '/lookerModelUserAccessRequests',
  LOOKER_FOLDER_ACCESS_REQUESTS: '/lookerFolderUserAccessRequests',
  EMPLOYEE_ACCESS_REQUESTS: '/employeeAccessRequests',
  TEAM_ACCESS_REQUESTS: '/teamAccessRequests',
  AUDIT: '/audit/company',
  BUSINESS_DEBTS: '/businessDebt',
  BENEFITS_TEMPLATES: '/benefitsTemplates',
  BENEFITS_ENROLMENTS: '/benefitsEnrolments',
  COMPANY: '/company',
  LOGIN_SETTINGS: '/loginSettings',
  COMPANY_SETTINGS: '/companySettings',
  TENANTS: '/tenants',
  TENANTS_ADMIN: '/tenantsAdmin',
  CONTRACTS_SETTINGS: '/contractsSettings',
  EMPLOYEE_SETTINGS: '/employeeSettings',
  REQUISITION_SETTINGS: '/requisitionSettings',
  JOB_POSTING_SETTINGS: '/jobPostingSettings',
  CAREER_PAGE_SETTINGS: '/careerPageSettings',
  POSTING_CAREER_PAGE_SETTINGS: `/postings/careerPageSettings`,
  DOCUMENT_SETTINGS: '/documentSettings',
  LIFECYCLE_SETTINGS: '/lifecycleSettings',
  CONTACT_HR: '/contactHr',
  TODO_SETTINGS: '/todoSettings',
  ORGANISATION_SETTINGS: '/organisationSettings',
  LOCATION_SETTINGS: '/locationSettings',
  SKILL_SETTINGS: '/skillsSettings',
  ACCESS_MANAGEMENT_SETTINGS: '/accessManagementSettings',
  PERFORMANCE_SETTINGS: '/performanceSettings',
  HELP_CENTER_SETTINGS: '/helpCenterSettings',
  COMMUNICATION_SETTINGS: '/communicationSettings',
  TIME_OFF_SETTINGS: '/timeOffSettings',
  ROADMAP_SETTINGS: '/roadmapsSettings',
  COMPANY_PREFERENCES: '/companyPreferences',
  SECURITY_SETTINGS: '/securitySettings',
  APP_SETTINGS: '/appSettings',
  APP_PUBLIC_SETTINGS: '/public/appSettings',
  CANDIDATE_SETTINGS: '/candidateSettings',
  HIRING_PROCESS_SETTINGS: '/hiringProcessSettings',
  APPLICATION_FORM_SETTINGS: '/applicationFormSettings',
  APPRVAL_STEP_CONFIGURATIONS: '/approvalStepConfigurations',
  COMPANY_GOALS: '/companyGoals',
  COMPANY_GOALS_STATS: '/companyGoals/stats',
  COMPANY_GOALS_WEIGHTS: '/companyGoals/updateWeights',
  COMPANY_KPIS: '/kpis/company',
  COMMUNICATION_GROUPS: '/communicationGroups',
  COMPENSATION_REVIEW: '/compensationReview',
  CONTRACTS: '/contracts',
  CONTRACT_TYPES: '/contractTypes',
  CONTRACTS_SELECTOR: '/contracts/selector',
  CV_REQUIREMENTS: '/cvRequirements',
  JIRA_INTEGRATION_PREFERENCES: '/jiraIntegrationPreferences',
  DOCUSIGN_INTEGRATION_PREFERENCES: '/docuSignIntegrationPreferences',
  GOOGLE_CALENDAR_INTEGRATION_PREFERENCES: '/googleCalendarIntegrationPreferences',
  DATA_ACCESS: '/dataAccess',
  DEPARTMENT: '/departments',
  DEPARTMENT_COMPENSATION_REVIEWS: '/departmentCompensationReviews',
  EMPLOYEE_BONUSES: `/employeeBonuses`,
  COMP_REVIEW_SETTINGS: '/compensationReviewSettings',
  DYNAMIC_GROUPS: '/dynamicGroups',
  DYNAMIC_GROUPS_IDS: '/dynamicGroupsIDs',
  EMAIL_TEMPLATES: '/emailTemplates',
  EMPLOYEE_EMAIL_TEMPLATES: '/employeeEmailTemplates',
  EMAIL_PLACEHOLDERS: '/emailPlaceholders',
  EMPLOYEE_EMAIL_PLACEHOLDERS: '/employeeEmailPlaceholders',
  EMPLOYEE_COMPENSATION_REVIEWS: '/employeeCompensationReviews',
  EMPLOYEES: '/employees',
  EMPLOYEES_SIMPLE: '/employees-simple',
  EMPLOYEES_SELECTOR: '/employees/selector',
  CONTRACTORS: '/contractors',
  ENGAGEMENT_QUESTIONS: '/engagementQuestions',
  ENGAGEMENT_ANSWERS: '/engagementAnswers',
  ENGAGEMENT_DRIVERS: '/engagementDrivers',
  ENGAGEMENT_SURVEYS: '/engagementSurveys',
  ENGAGEMENT_SURVEY_SETTINGS: '/engagementSurveySettings',
  SUBSCRIPTIONS_INFO_API: `/subscriptions/info`,
  ENGAGEMENT_AUDIENCES: '/audiences',
  FAVOURITES: '/favourites',
  FAQ: '/faq',
  QUESTION_OF_THE_DAY: '/engagementQuestions',
  PAY_GROUPS: '/payGroups',
  PAYROLL: '/payroll',
  PAYROLL_CYCLES: '/payroll/cycles',
  PAYROLL_SETTINGS: '/payroll/settings',
  RECAPTCHA: '/recaptcha',
  CONTRACTING_COUNTRIES: '/contractingCountries',
  CHANGELOG: '/changelog',
  CHANGELOG_FIELDS: '/changelogFields',
  POSITIONS_CHANGELOG_FIELDS: '/positionChangelogFields',
  CUSTOM_FIELDS: '/employees/customFields',
  ENTITIES: '/entities',
  FEEDBACK: '/users/feedback',
  FUNCTIONS: '/functions',
  INTERVIEWS: '/interviews',
  CANDIDATE_BULK_EDIT: '/candidateBulkEdit',
  CANDIDATES_BULK_MOVE_TO_STAGE: '/candidateBulkMoveToStage',
  CANDIDATES_BULK_SEND_ONLINE_TEST: '/candidateBulkSendOnlineTest',
  CANDIDATES_BULK_SNOOZE: '/candidateBulkSnooze',
  CANDIDATES_BULK_UNSNOOZE: '/candidateBulkUnsnooze',
  HEALTH: '/health',
  HIRING_PROCESS: '/hiringProcess',
  BULK_CREATE_HIRING_PROCESS: '/hiringProcess/hiringStages/bulkCreateHiringProcesses',
  BULK_ARCHIVE_SPECIALISATION_HIRING_STAGES:
    '/hiringProcess/specialisationHiringStages/bulkArchive',
  SPECIALISATIONS_HIRING_PROCESS_OVERVIEW: '/specialisations/hiringProcessOverview',
  HOME_TASK: '/homeTask',
  KPI_WEIGHTS: '/kpis/updateWeight',
  KPIS: '/kpis',
  GOALS: '/goals',
  KPIS_APPROVALS: '/kpis/kpisToApprove',
  KPI_TARGETS: '/kpi_targets',
  KPI_RULE: '/kpiTemplateRules',
  KPI_TEMPLATE: '/kpiTemplates',
  KPIS_STATS: '/kpis/stats',
  KPI_NOTIFICATIONS: '/kpisNotifications',
  GOAL_NOTIFICATIONS: '/goalNotifications',
  SYSTEM_NOTIFICATIONS: '/systemNotifications',
  COMPANY_COMPENSATION_REVIEWS: '/companyCompensationReviews',
  LOCATIONS: '/locations',
  DOCUMENTS: '/documents',
  DOCUMENTS_REQUEST_BY_TEMPLATE: '/documents/uploads/request_document',
  DOCUMENTS_UPLOADS_BULK: `/documents/uploads/bulk`,
  DOCUMENT_CATEGORIES: '/documentCategories',
  LOGIN: '/login',
  CONNECT_GMAIL: '/connect-gmail',
  CONNECT_CALENDAR: '/connect-calendar',
  LOGOUT: '/logout',
  NOTIFICATIONS: '/inbox',
  NOTIFICATION_TEMPALTE: '/notifications',
  TASK_NOTIFICATIONS: '/taskNotifications',
  LABEL: '/employeeManualLabelAssignments',
  HR_ONBOARDING: '/hrOnboarding',
  OFFBOARDING_EMPLOYEES: '/offboardingEmployees',
  PERFORMANCE: '/performance',
  PIP: '/pip',
  PROBATION_COMMENTS: '/probation',
  PLAYBOOKS: '/confluencePlaybooks',
  HIRING_STAGES: '/hiringStages',
  RECRUITMENT_GROUPS: '/recruitmentGroups',
  REFERRAL_BONUSES: '/referralBonuses',
  REQUISITIONS: '/requisitions',
  REQUISITIONS_SELECTOR: '/requisitions/selector',
  REQUISITIONS_VIEW_CONFIDENTIAL_BUTTON: '/requisitions/canViewConfidentialRequisitions',
  REQUISITIONS_STATS: '/requisitions/stats',
  REVIEW_CYCLES: '/review-cycles',
  REVIEW_CYCLE_GENERAL_INFO: '/reviewCycleGeneralInfo',
  REVIEW_CYCLE_TEMPLATES: '/reviewCycleTemplates',
  ELIGIBLE_GROUPS: '/eligibleGroups',
  SCORECARD_SECTIONS: '/scorecardSections',
  REVIEWS: '/reviews',
  REVOLUTERS: '/employees/all',
  REVOLUTERS_STATISTICS: '/statistics/revoluters/count',
  REVOLUTERS_STATS: '/employees/all/stats',
  PROBATION: '/probation/all',
  PIP_ITEMS: '/pip/all',
  PROBATION_STATS: '/probation/all/stats',
  PIP_STATS: '/pip/all/stats',
  PROBATION_SCORECARDS: '/probation/scorecards',
  PROBATION_CYCLE: '/probation/cycle',
  EMPLOYEE_PERFORMANCE_CYCLES: '/employeePerformance/cycles',
  RISK: '/risk',
  ROADMAPS: '/roadmaps',
  COMPANY_ROADMAPS: '/roadmaps/companyRoadmaps',
  ROADMAPS_MAIN: '/roadmaps/main',
  ROADMAPS_STATS: '/roadmaps/stats',
  ROLES: '/roles',
  SPECIALISATIONS: '/specialisations',
  SCORECARD: '/scorecards',
  SCORECARD_TEMPLATES: '/scorecardTemplates',
  INTERVIEW_SCORECARD_TEMPLATES: '/interviewScorecardTemplates',
  SCREEN_CALL: '/screenCall',
  SELECTORS: '/selectors',
  SENIORITY: '/seniority',
  SKILLS: '/skills',
  SLACK_INTEGRATION_PREFERENCES: '/slackIntegrationPreferences',
  VALUES: '/values',
  SUPPORT_TOOL: '/supportTool',
  MEETINGS_TRACKER: '/meetings/events',
  TESTING_STAGE: '/utils/testingStage',
  SANDBOX_MODE: '/sandboxMode/update',
  SANDBOX_MODE_RESET: '/sandboxMode/reset',
  SANDBOX_INVITATION: '/sandboxInvitation',
  SCORECARDS_GENERATIONS: '/scorecardsGenerations',
  JOB_POSTING: '/jobPostings',
  LINKEDIN_POSTINGS: '/linkedin/postings',
  TEAMS: '/teams',
  TEAMS_HISTORY: '/teams/history',
  USER: '/users',
  WHOAMI: '/whoami',
  USER_AVATAR: '/userAvatar',
  FILES: '/files',
  OWNERSHIP_TRANSFER_NOTIFICATIONS: '/ownershipTransferNotifications',
  EMPLOYEE_CHANGING_TEAM_NOTIFICATIONS: '/employeeChangingTeamNotifications',
  BENCHMARKS: '/benchmarks',
  BANNERS: '/banners',
  BUDGET_POOL_CONFIGS: '/budgetPoolConfigs',
  TIME_OFF: '/timeOff',
  PROMOTION: '/promotion',
  ATTENDANCE_TRACKING: '/attendanceTracking',
  PROMOTION_NOMINATIONS: '/promotionNominations',
  PROMOTION_PROGRESSION: '/promotion/progression',
  LEADERSHIP_POSITIONS: '/leadershipPositions',
  RESIGNATIONS: '/resignations',
  RESIGNATIONS_STATS: '/resignations/statistics',
  RESIGNATION_REASONS: '/resignations/staticReasons',
  RESIGNATION_NEXT_STEPS: '/resignations/nextSteps',
  ONE_TO_ONES: '/oneToOnes',
  EMPLOYEES_BULK_EDIT: '/employees/bulkEdit',
  CONTRACTS_BULK_EDIT: '/contracts/bulkEdit',
  EMPLOYEE_UPLOADS: `/employeeUploads`,
  TICKETS: `/tickets`,
  TIME_OFF_REQUESTS_BULK_UPLOAD: `/timeOff/requests/bulk`,
  TIME_OFF_BALANCES_BULK_UPLOAD: `/timeOff/balanceBulkUploads`,
  BACKGROUND_JOBS: '/backgroundJobs',
  BACKGROUND_JOBS_STATS: '/backgroundJobs/stats',
  EMPLOYEE_FEEDBACK: '/feedback/employees',
  PROBATION_TEMPLATES: '/probation/probationTemplates',
  TEMPLATES_PREVIEW: '/timelinesPreview',
  GDPR: '/gdpr',
  SCREENING_SETTINGS: '/screeningSettings',
  OFFER_SETTINGS: '/offerFormSettings',
  REPORTING: '/data/reporting',
  KEY_PERSONS_SETTINGS: '/keyPersonSettingsPreferences',
  BOOKING_LINK_DEFINITIONS: '/bookingLinkDefinitions',
  BOOKING_LINK: '/bookingLinks',
  AVAILABLE_SLOTS: '/availableSlots',
  LOCATIONS_WITH_COUNTRIES: '/locationsWithCountries',
  APPLICANT_GENDERS: '/interviews/applications/applicantGenders',
  APPLICANT_SEXUAL_ORIENTATIONS: '/interviews/applications/applicantSexualOrientations',
  APPLICANT_DISABILITIES: '/interviews/applications/applicantDisabilities',
  APPLICANT_ETHNICITIES: '/interviews/applications/applicantEthnicities',
  APPLICANT_DIVERSITY_SURVEY: '/applicantDiversitySurvey',
  APPLICANT_UNIVERSITIES: '/interviews/applications/applicantUniversities',
  APPLICANT_EDUCATION_MAJORS: '/interviews/applications/applicantEducationMajors',
  APPLICANT_DEGREES: '/interviews/applications/applicantDegrees',
  APPLICANT_WORK_EXPERIENCE_COMPANIES:
    '/interviews/applications/applicantWorkExperienceCompanies',
  APPLICANT_WORK_EXPERIENCE_POSITIONS:
    '/interviews/applications/applicantWorkExperiencePositions',
  FAQ_BULK_UPLOAD: '/faq/bulkUpload',
  FULL_INTERVIEW_ROUNDS: `/interviews/candidates/:id/fullInterviewRounds`,
  COUNTRY_HIRING_SETTINGS: '/countryHiringSettings',
  CRM: '/interviews/interviewRoundCRM',
  HIRING_ENABLED_LOCATIONS: '/requisitions/hiringEnabledLocations',
  ONBOARDING_CHECKPOINTS: '/onboardingCheckpoints',
  ONBOARDING_CHECKPOINTS_V2: '/onboardingFlow/2/onboardingCheckpoints',
  REQUISITIONS_UPLOADS: '/requisition_bulk_upload',
  PERFORMANCE_UPLOADS: '/legacy_timelines_bulk_upload',
  JOB_POSTINGS_UPLOAD: '/job_posting_bulk_upload',
  CANDIDATES_UPLOAD: '/candidate_bulk_upload',
  CANDIDATE_CONSENT_LINKS: '/candidateConsentLinks',
  CONTRACTS_UPLOAD: '/contracts/bulkUpload',
  COMPENSATION_SETTINGS: '/totalCompensation/settings',
}

export const COOKIE = {
  API_TOKEN: 'api_token',
  AUTHENTICATED: 'authenticated',
  SIGNUP_STATE: 'signup_state',
  PERFORMANCE_INFO_SEEN: 'performance_info_seen',
  DELIVERABLES_INFO_SEEN: 'deliverables_info_seen',
  UPWARDS_INFO_SEEN: 'upwards_info_seen',
  KPI_IMPORTANT_INFO_SEEN: 'kpi_important_info_seen',
  ORIGINAL_TOKEN: 'original_api_token',
  PERFORMANCE_REJECT_DISMISSED: 'performance_reject_dismissed',
  LAST_CALENDAR_SYNC: 'last_calendar_sync',
  SANDBOX_MODE_ENABLED: 'sandboxModeEnabled',
  REFRESH_SELF_KPI_TIMEOUT: 'refresh_self_kpi_timeout',
  COOKIE_BANNER_CLOSED: 'cookie_banner_closed',
  COOKIE_ANALYTICS_ENABLED: 'cookie_analytics_enabled',
  COOKIE_CAREERS_ANALYTICS: 'cookie_careers_analytics',
}

export const SESSION_STORAGE = {
  TWO_FACTOR_LOGIN: 'twoFactorLogin',
  ONBOARDING_PREVIEW: 'onboardingPreview',
  UTM_PARAMS: 'utmParams',
}

export const LOCAL_STORAGE = {
  FEEDBACK_DATE: 'feedbackDate',
  SHOW_MY_TEAMS: 'showMyTeams',
  SHOW_ARCHIVED_TEAMS: 'showArchivedTeams',
  SHOW_MY_FUNCTIONS: 'showMyFunctions',
  SHOW_MY_ROLES: 'showMyRoles',
  SHOW_MY_SPECIALISATIONS: 'showMySpecialisations',
  SHOW_MY_DEPARTMENTS: 'showMyDepartments',
  SHOW_CANDIDATES_ASSIGNED_TO_ME: 'showCandidatesAssignedToMe',
  SHOW_MY_REPORTS: 'showMyReports',
  SHOW_TESTING_ACCOUNTS: 'showTestingAccounts',
  SHOW_FAVOURITE_EMPLOYEES: 'showFavouriteEmployees',
  SHOW_FAVOURITE_TEAMS: 'showFavouriteTeams',
  SHOW_FAVOURITE_SKILLS: 'showFavouriteSkills',
  SHOW_FAVOURITE_SPECIALISATIONS: 'showFavouriteSpecialisations',
  SHOW_FAVOURITE_ROLES: 'showFavouriteRoles',
  SHOW_FAVOURITE_REQUISITIONS: 'showFavouriteRequisitions',
  SHOW_FAVOURITE_DEPARTMENTS: 'showFavouriteDepartments',
  SHOW_FAVOURITE_FUNCTIONS: 'showFavouriteFunctions',
  SHOW_ARCHIVED_CANDIDATES: 'showArchivedCandidates',
  SHOW_CONFIDENTIAL_CANDIDATES: 'showConfidentialCandidates',
  EMPLOYEE_PROFILE_TAB_ORDER: 'emloyeeProfileTabOrder',
  SHOW_PREVIOUS_RATINGS: 'showPreviousPerformanceRatings',
  SHOW_TALENT_PERFORMANCE_HOF_HELP_MESSAGE: 'showTalentPerformanceHOFHelpMessage',
  SHOW_TALENT_PERFORMANCE_HOD_HELP_MESSAGE: 'showTalentPerformanceHODHelpMessage',
  SHOW_TALENT_PERFORMANCE_COMPANY_HELP_MESSAGE: 'showTalentPerformanceCompanyHelpMessage',
  TIME_OFF_BALANCE_YTD: 'timeOffBalanceYTD',
  ARCHIVING_DETAILS_REMEMBER_CHOICE: 'archivingDetailsRememberChoice',
  SHOW_ONLY_SUBTASKS: 'showOnlySubtasks',
  SHOW_HISTORY: 'showHistory',
  SHOW_PROBATION_ASSIGNED_TO_ME: 'showProbationAssignedToMe',
  SHOW_PROBATION_PENDING_COMMITTEE: 'showProbationPendingCommittee',
  SHOW_ONBOARDING_ASSIGNED_TO_ME: 'showOnboardingAssignedToMe',
  SHOW_OFFBOARDING_ASSIGNED_TO_ME: 'showOffboardingAssignedToMe',
  SHOW_RESIGNATION_ASSIGNED_TO_ME: 'showResignmentAssignedToMe',
  SHOW_DOCUMENT_TEMPLATES_ASSIGNED_TO_ME: 'showDocumentTemplatesAssignedToMe',
  SHOW_DOCUMENTS_ASSIGNED_TO_ME: 'showDocumentsAssignedToMe',
  SHOW_DOCUMENT_RULES_ASSIGNED_TO_ME: 'showDocumentRulersAssignedToMe',
  SHOW_NOTIFICATIONS_ASSIGNED_TO_ME: 'showNotificationsAssignedToMe',
  SHOW_BENEFITS_ENROLMENT_ASSIGNED_TO_ME: 'showBenefitsEnrolmentAssignedToMe',
  SHOW_DYNAMIC_GROUPS_ASSIGNED_TO_ME: 'showDynamicGroupsAssignedToMe',
  SHOW_COMMUNICATION_GROUPS_ASSIGNED_TO_ME: 'showCommunicationGroupsAssignedToMe',
  SHOW_LINKED_ACCOUNTS_VIDEO_MODAL: 'showLinkedAccountsVideoModal',
  SHOW_SALARY_BONUS_RECOMMANDATIONS: 'showSalaryBonusRecommandations',
  PAYCYCLE_OWNED_BY_ME: 'showPaycycleOwnedByMe',
  PAYCYCLE_WITH_ISSUES: 'showPaycycleWithIssues',
  SHOW_MY_BOOKING_LINK_DEFINITIONS: 'showMyBookinkLinkDefinitions',
  CV_SCREENING_SESSION: 'cvScreeningSession_v2',
  CV_SCREENING_PERFORMANCE: 'cvScreeningPerformance',
  CANDIDATES_TABLE_CONTEXT: 'candidatesTableContext',
  LOCATION_HISTORY: 'locationHistory',
  HIRING_STAGE_CREATED_SCORECARD: 'hiringStageCreatedScorecard',
  SHOW_PERF_REVIEW_PREFILLED: 'showPerfReviewPrefilled',
  TIME_MANAGEMENT_FILTER: 'timeManagementFilter',
}

export enum Queries {
  CycleId = 'cycle_id',
  EmployeeID = 'employee_id',
  Probation = 'probation',
  RequestId = 'request_id',
  Scorecard = 'scorecard',
  Type = 'type',
  Category = 'category',
  StageId = 'stage_id',
  CriteriaID = 'criteria_id',
  EditingId = 'editing_id',
  CheckpointNumber = 'checkpoint',
  ReviewerId = 'reviewer_id',
}

export enum selectorKeys {
  goal_approval_statuses = 'goal_approval_statuses',
  default_reviewed_employee_type = 'default_reviewed_employee_type',
  audience_types = 'audience_types',
  access_request_permissions = 'access_request_permissions',
  access_request_statuses = 'access_request_statuses',
  active_employee_emails = 'active_employee_emails',
  all_employees = 'all_employees',
  employee_emails = 'employee_emails',
  all_employee_emails = 'all_employee_emails', // unlike employee_emails includes not only active ones
  request_probation_scorecard_relations = 'request_probation_scorecard_relations',
  all_job_titles = 'all_job_titles',
  all_screening_check_types = 'all_screening_check_types',
  approved_functional_skills = 'approved_functional_skills',
  approved_role = 'approved_role',
  approved_specialisations = 'approved_specialisations',
  approved_pending_specialisations = 'approved_pending_specialisations',
  communication_groups = 'communication_groups',
  communication_group_audience_types = 'communication_group_audience_types',
  communication_group_statuses = 'communication_group_statuses',
  audit = 'audit',
  audit_assignee_name = 'audit_assignee_name',
  audit_bonus_impact = 'audit_bonus_impact',
  audit_category = 'audit_category',
  audit_issue_id = 'audit_issue_id',
  audit_parent_issue_key = 'audit_parent_issue_key',
  issue_severity = 'issue_severity',
  issue_type = 'issue_type',
  banner_severities = 'banner_severities',
  benchmark_types = 'benchmark_types',
  benchmark_status = 'benchmark_statuses',
  benchmark_tags = 'benchmark_tags',
  benchmark_changelog_fields = 'benchmark_changelog_fields',
  benefits_template_categories = 'benefits_template_categories',
  benefits_template_statuses = 'benefits_template_statuses',
  benefits_template_contribution_methods = 'benefits_template_contribution_methods',
  benefits_template_contribution_frequencies = 'benefits_template_contribution_frequencies',
  benefits_package_enrolment_methods = 'benefits_package_enrolment_methods',
  benefits_template_cut_off_periods = 'benefits_template_cut_off_periods',
  benefits_template_names = 'benefits_template_names',
  benefits_package_relationship_types = 'benefits_package_relationship_types',
  benefits_custom_fields = 'benefits_custom_fields',
  benefits_employee_enrolment_statuses = 'benefits_employee_enrolment_statuses',
  global_fields = 'global_fields',
  grade_calculation_methods = 'grade_calculation_methods',
  cx_issue_id = 'cx_issue_id',
  audit_lateness = 'audit_lateness',
  audit_resolution = 'audit_resolution',
  audit_severity = 'audit_severity',
  cx_issue_severity = 'cx_issue_severity',
  cx_bug_severity = 'cx_bug_severity',
  cx_bug_assignee_name = 'cx_bug_assignee_name',
  cx_bug_id = 'cx_bug_id',
  cx_bug_lateness = 'cx_bug_lateness',
  audit_severity_v2 = 'audit_severity_v2',
  automatic = 'automatic',
  business_debt_percent = 'business_debt_percent',
  candidate_inactivity_reasons = 'candidate_inactivity_reasons',
  candidate_email_when_to_send = 'candidate_email_when_to_send',
  employee_email_when_to_send = 'employee_email_when_to_send',
  changelog_item_statuses = 'changelog_item_statuses',
  company_goals = 'company_goals',
  company_kpis = 'company_kpis',
  company_compensation_review_currencies = 'company_compensation_review_currencies',
  company_compensation_reviews = 'company_compensation_reviews',
  company_compensation_review_statuses = 'company_compensation_review_statuses',
  department_compensation_review_currencies = 'department_compensation_review_currencies',
  company_sizes = 'company_sizes',
  company_industries = 'company_industries',
  industry_categories = 'industry_categories',
  contract_inactivity_reasons = 'contract_inactivity_reasons',
  contract_lifecycle_statuses = 'contract_lifecycle_statuses',
  contract_pay_groups = 'contract_pay_groups',
  active_pay_groups = 'active_pay_groups',
  contract_types = 'contract_types',
  contract_terms = 'contract_terms',
  contract_sign_on_bonus_types = 'contract_sign_on_bonus_types',
  countries = 'countries',
  country_calling_codes = 'country_calling_codes',
  culture_percentiles = 'culture_percentiles',
  currencies = 'currencies',
  custom_field_employee_accesses = 'custom_field_employee_accesses',
  custom_field_input_types = 'custom_field_input_types',
  custom_field_statuses = 'custom_field_statuses',
  custom_field_names = 'custom_field_names',
  custom_field_sections = 'custom_field_sections',
  cycle = 'cycle',
  cycle_offsets = 'cycle_offsets',
  data_access_review_statuses = 'data_access_review_statuses',
  deliverable_cycle_offsets = 'deliverable_cycle_offsets',
  department = 'department_active',
  department_kpis = 'department_kpis',
  diversity_ethnicities = 'diversity_ethnicities',
  diversity_genders = 'diversity_genders',
  diversity_sexual_orientation = 'diversity_sexual_orientation',
  diversity_educational_backgrounds = 'diversity_educational_backgrounds',
  diversity_religions = 'diversity_religions',
  diversity_disabilities = 'diversity_disabilities',
  diversity_carer_types = 'diversity_carer_types',
  diversity_age_ranges = 'diversity_age_ranges',
  diversity_socioeconomic_backgrounds = 'diversity_socioeconomic_backgrounds',
  document_categories = 'document_categories',
  document_self_serve_templates = 'document_self_serve_templates',
  document_template_rules = 'document_template_rules',
  document_template_rules_individual_requesting = 'document_template_rules_individual_requesting',
  document_template_rules_individual_generation = 'document_template_rules_individual_generation',
  document_templates = 'document_templates',
  document_template_types = 'document_template_types',
  document_rule_statuses = 'document_rule_statuses',
  uploaded_documents_status_choices = 'uploaded_documents_status_choices',
  uploaded_documents_approval_status_choices = 'uploaded_documents_approval_status_choices',
  document_rule_schedule_types = 'document_rule_schedule_types',
  document_rule_types = 'document_rule_types',
  dynamic_group_schedules = 'dynamic_group_schedules',
  dynamic_group_sql_query_databases = 'dynamic_group_sql_query_databases',
  dynamic_group_statuses = 'dynamic_group_statuses',
  dynamic_group_types = 'dynamic_group_types',
  dynamic_groups = 'dynamic_groups',
  custom_fields_labels = 'custom_fields_labels',
  dynamic_group_audience_selection_types = 'dynamic_group_audience_selection_types',
  group_setting_audience_selection_types = 'group_setting_audience_selection_types',
  group_setting_sql_query_databases = 'group_setting_sql_query_databases',
  employee = 'employee',
  employee_resignation_notice_period = 'employee_resignation_notice_period',
  employee_resignation_statuses = 'employee_resignation_statuses',
  employee_resignation_employee = 'employee_resignation_employee',
  backfill_employee = 'backfill_employee',
  department_permissions = 'department_permissions',
  team_permissions = 'team_permissions',
  function_permissions = 'function_permissions',
  role_permissions = 'role_permissions',
  specialisation_permissions = 'specialisation_permissions',
  candidate_permissions = 'candidate_permissions',
  hiring_pipeline_permissions = 'hiring_pipeline_permissions',
  email_template_subjects = 'email_template_subjects',
  employee_email_template_subjects = 'employee_email_template_subjects',
  email_template_statuses = 'email_template_statuses',
  employee_email_template_statuses = 'employee_email_template_statuses',
  employee_email_template_categories = 'employee_email_template_categories',
  employee_access_request_sources = 'employee_access_request_sources',
  employee_access_request_tools = 'employee_access_request_tools',
  employee_access_request_types = 'employee_access_request_types',
  employee_changelog_fields = 'employee_changelog_fields',
  employee_changelog_values = 'employee_changelog_values',
  employee_work_authorisation_statuses = 'employee_work_authorisation_statuses',
  employee_relocation_types = 'employee_relocation_types',
  employee_contract_payment_frequency = 'contract_salary_payment_frequencies',
  employee_contract_probation_unit = 'contract_notice_period_time_units',
  probation_committee_hr_managers = 'probation_committee_hr_managers',
  probation_hr_managers = 'probation_hr_managers',
  employee_contract_status = 'contract_statuses',
  employee_contract_time_unit = 'contract_salary_time_units',
  employee_documents_sources = 'employee_documents_sources',
  employee_documents_statuses = 'employee_documents_statuses',
  employee_label_modes = 'employee_label_modes',
  employee_label_statuses = 'employee_label_statuses',
  employee_label_visibilities = 'employee_label_visibilities',
  employee_parent_kpis = 'employee_parent_kpis',
  employee_pending = 'employee_pending',
  hr_onboarding_employees = 'hr_onboarding_employees',
  employee_profile_sub_sections = 'employee_profile_sub_sections',
  all_employee_profile_sub_sections = 'all_employee_profile_sub_sections',
  employee_requisitions_status = 'employee_requisitions_status',
  employee_scorecards_types = 'employee_scorecards_types',
  employee_status = 'employee_status',
  employee_status_pending = 'employee_status_pending',
  employees_for_teams = 'employees_for_teams',
  hr_onboarding_process_statuses = 'hr_onboarding_process_statuses',
  hr_onboarding_stages = 'hr_onboarding_stages',
  years_to_date = 'years_to_date',
  engagement_drivers = 'engagement_drivers',
  engagement_driver_statuses = 'engagement_driver_statuses',
  engagement_question_status = 'engagement_question_status',
  engagement_question_texts = 'engagement_question_texts',
  engagement_settings_sent_every_options = 'engagement_settings_sent_every_options',
  engagement_survey_statuses = 'engagement_survey_statuses',
  engagement_survey_round_statuses = 'engagement_survey_round_statuses',
  engagement_question_comment_options = 'engagement_question_comment_options',
  engagement_surveys = 'engagement_surveys',
  engagement_surveys_for_culture = 'engagement_surveys_for_culture',
  engagement_survey_titles = 'engagement_survey_titles',
  engagement_survey_titles_sorted = 'engagement_survey_titles_sorted',
  engagement_question_types = 'engagement_question_types',
  engagement_survey_recurrence = 'engagement_survey_recurrence',
  engagement_answer_scores = 'engagement_answer_scores',
  engagement_survey_campaigns = 'engagement_survey_campaigns',
  activation_email_notifications = 'activation_email_notifications',
  magic_link_email_notifications = 'magic_link_email_notifications',
  allowed_email_domains = 'allowed_email_domains',
  approval_processes = 'approval_processes',
  approval_step_approver_types = 'approval_step_approver_types',
  team_approval_relationships = 'team_approval_relationships',
  role_approval_relationships = 'role_approval_relationships',
  specialisation_approval_relationships = 'specialisation_approval_relationships',
  employee_kpi_approval_relationships = 'employee_kpi_approval_relationships',
  role_kpi_approval_relationships = 'role_kpi_approval_relationships',
  team_kpi_approval_relationships = 'team_kpi_approval_relationships',
  department_approval_relationships = 'department_approval_relationships',
  company_approval_relationships = 'company_approval_relationships',
  department_kpi_approval_relationships = 'department_kpi_approval_relationships',
  function_kpi_approval_relationships = 'function_kpi_approval_relationships',
  skill_approval_relationships = 'skill_approval_relationships',
  requisition_approval_relationships = 'requisition_approval_relationships',
  job_posting_approval_relationships = 'job_posting_approval_relationships',
  group_access_request_approval_relationships = 'group_access_request_approval_relationships',
  employee_relations = 'employee_relations',
  custom_scorecard_question_types = 'custom_scorecard_question_types',
  custom_scorecard_sections_show_options = 'custom_scorecard_sections_show_options',
  entity = 'entity',
  active_entities = 'active_entities',
  probation_templates = 'probation_templates',
  functions = 'functions',
  legal_sexes = 'legal_sexes',
  groups = 'groups',
  grades = 'grades',
  half_yearly_cycles = 'half_yearly_cycles',
  helios_metabase_databases = 'helios_metabase_databases',
  employee_access_request_databases_names = 'employee_access_request_databases_names',
  helios_metabase_read_only_databases = 'helios_metabase_read_only_databases',
  hiring_scorecard_template_type = 'hiring_scorecard_template_type',
  search_item_categories = 'search_item_categories',
  hiring_stage_duration_units = 'hiring_stage_duration_units',
  hiring_stage_types = 'hiring_stage_types',
  hiring_stages = 'hiring_stages',
  cv_criterias = 'cv_criterias',
  home_task = 'home_task',
  identification_document_types = 'identification_document_types',
  indefinite_residence_types = 'indefinite_residence_types',
  interview_feedback_result_choices = 'interview_feedback_result_choices',
  interview_scorecard_template_statuses = 'interview_scorecard_template_statuses',
  interview_scorecard_templates = 'interview_scorecard_templates',
  interviews_candidates = 'interviews_candidates',
  interviews_work_experience_managed_people_options = 'interviews_work_experience_managed_people_options',
  interview_round_archived_reasons = 'interview_round_archived_reasons',
  specialisation_hiring_stages = 'specialisation_hiring_stages',
  job_description = 'job_description',
  job_description_category = 'job_description_category',
  key_risk_person_types = 'key_risk_person_types',
  key_risk_profile_succession_plan_succession_strategies = 'key_risk_profile_succession_plan_succession_strategies',
  kpi_application_strategies = 'kpi_application_strategies',
  kpi_categories = 'kpi_categories',
  kpi_category = 'kpi_category',
  kpi_changelog_fields = 'kpi_changelog_fields',
  kpi_changelog_values = 'kpi_changelog_values',
  kpi_goals = 'kpi_goals',
  allowed_kpi_strategies = 'allowed_kpi_strategies',
  allowed_kpi_categories = 'allowed_kpi_categories',
  cycle_durations = 'cycle_durations',
  review_level_definitions = 'review_level_definitions',
  kpi_names = 'kpi_name',
  kpi_performance_percent = 'kpi_performance_percent',
  kpi_performance_types = 'kpi_performance_types',
  kpi_sql_query_databases = 'kpi_sql_query_databases',
  kpi_sql_types = 'kpi_sql_types',
  kpi_statuses = 'kpi_statuses',
  kpi_template_department_fields = 'kpi_template_department_fields',
  kpi_template_employees_fields = 'kpi_template_employees_fields',
  kpi_template_function_fields = 'kpi_template_function_fields',
  kpi_template_rules = 'kpi_template_rules',
  kpi_template_teams_fields = 'kpi_template_teams_fields',
  kpi_templates = 'kpi_templates',
  kpi_types = 'kpi_types',
  kpi_unique_names = 'kpi_unique_names',
  kpi_units = 'kpi_units',
  kpi_weight_modes = 'kpi_weight_modes',
  kpi_entity_names = 'kpi_entity_names',
  kpi_targets_statuses = 'kpi_targets_statuses',
  employee_attendance_tracking_relationships = 'employee_attendance_tracking_relationships',
  offer_approval_relationships = 'offer_approval_relationships',
  jira_status_categories = 'jira_status_categories',
  jira_projects = 'jira_projects',
  language = 'language',
  location = 'location',
  location_names = 'location_names',
  location_types = 'location_types',
  location_statuses = 'location_statuses',
  location_labels = 'location_labels',
  looker_folders = 'looker_folders',
  looker_lookml_models = 'looker_lookml_models',
  looker_permission_sets = 'looker_permission_sets',
  manager = 'manager',
  manual_employee_labels = 'manual_employee_labels',
  marital_statuses = 'marital_statuses',
  month_days = 'month_days',
  pay_date_schedules = 'pay_date_schedules',
  none = 'none',
  object_permission_statuses = 'object_permission_statuses',
  notification_categories = 'notification_categories',
  notification_priorities = 'notification_priorities',
  notification_reviewer_relation = 'notification_reviewer_relation',
  notification_titles = 'notification_titles',
  orphan_company_kpis = 'orphan_company_kpis',
  passing_probation_percent = 'passing_probation_percent',
  pay_date_lands_on_weekend_choices = 'pay_date_lands_on_weekend_choices',
  pay_frequencies = 'pay_frequencies',
  pay_authorities_frequencies = 'pay_authorities_frequencies',
  pay_group_triggers = 'pay_group_triggers',
  performance = 'performance',
  performance_grades = 'performance_grades',
  calibration_grades = 'calibration_grades',
  performance_score_labels = 'performance_score_labels',
  performance_whitelist_status_choices = 'performance_whitelist_status_choices',
  performance_stages = 'performance_stages',
  permissions = 'permissions',
  priority = 'requisitions_priority',
  referral_bonuses_bonus_type = 'referral_bonuses_bonus_type',
  referral_bonuses_tax_type = 'referral_bonuses_tax_type',
  requisitions_priority = 'requisitions_priority',
  requisitions_status = 'requisitions_status',
  requisitions_title = 'requisitions_title',
  resolution_date_time = 'resolution_date_time',
  requisition_changelog_fields = 'requisition_changelog_fields',
  review_cycles = 'review_cycles',
  reviewCycleStatuses = 'cycle_statuses',
  retention_period_duration_units = 'retention_period_duration_units',
  requisitions = 'requisitions',
  revolearn_categories = 'revolearn_categories',
  revolearn_courses = 'revolearn_courses',
  revoluters_all = 'revoluters_all',
  revoluters_all_names = 'revoluters_all_names',
  right_to_work_types = 'right_to_work_types',
  right_to_work_statuses = 'right_to_work_statuses',
  risk_all_levels = 'risk_all_levels',
  risk_categories = 'risk_categories',
  risk_control_actions = 'risk_control_actions',
  risk_incident_status = 'risk_incident_status',
  risk_indicator_quality_scores = 'risk_indicator_quality_scores',
  risk_levels = 'risk_levels',
  risk_reasons = 'risk_reasons',
  risk_reasons_severities = 'risk_reasons_severities',
  risk_residual_scores = 'risk_residual_scores',
  risk_severities = 'risk_severities',
  risk_status = 'risk_status',
  risk_types = 'risk_types',
  risk_types_2 = 'risk_types_2',
  roadmap_priority = 'roadmap_priority',
  roadmap_progress_percent = 'roadmap_progress_percent',
  roadmap_stage = 'roadmap_stage',
  roadmap_status = 'roadmap_status',
  roadmap_organisational_units = 'roadmap_organisational_units',
  roadmap_types = 'roadmap_types',
  roadmap_connections = 'roadmap_connections',
  roadmap_child_status = 'roadmap_child_status',
  role = 'role',
  roles_status = 'roles_status',
  salary_deduction_types = 'salary_deduction_types',
  salary_sacrifice_frequencies = 'salary_sacrifice_frequencies',
  salary_sacrifice_schemas = 'salary_sacrifice_schemas',
  salary_sacrifice_status = 'salary_sacrifice_statuses',
  scorecard = 'scorecard',
  scorecard_category = 'scorecard_category',
  screening_status_categories = 'screening_status_categories',
  key_risk_profile_statuses = 'key_risk_profile_statuses',
  screening_check_types = 'screening_check_types',
  employees_linked_accounts_types = 'employees_linked_accounts_types',
  seniority = 'seniority',
  seniority_sublevels = 'seniority_sublevels',
  sent_envelope_statuses = 'sent_envelope_statuses',
  skill_complete = 'skill_complete',
  skills = 'skills',
  skillset = 'skillset',
  skillsets_status = 'skillsets_status',
  slack = 'slack',
  slack_bots = 'slack_bots',
  specialisations = 'specialisations',
  team = 'team_active',
  team_kpis = 'team_kpis',
  team_nps_languages = 'team_nps_languages',
  team_owner = 'team_owner',
  team_projects = 'team_projects',
  teams_approval_status = 'teams_approval_status',
  department_approval_status = 'department_approval_status',
  templated_notification_event_types = 'templated_notification_event_types',
  templated_notification_delivery_types = 'templated_notification_delivery_types',
  templated_notification_delivery_types_for_email = 'templated_notification_delivery_types_for_email',
  templated_notification_statuses = 'templated_notification_statuses',
  templated_notification_template_types = 'templated_notification_template_types',
  templated_notification_types = 'templated_notification_types',
  templated_notifications = 'templated_notifications',
  templated_notification_request_message_log_statuses = 'templated_notification_request_message_log_statuses',
  templated_notification_audience_selection_types = 'templated_notification_audience_selection_types',
  templated_notification_sql_query_dbs = 'templated_notification_sql_query_dbs',
  templated_notification_audience_selection_types_for_email = 'templated_notification_audience_selection_types_for_email',
  temporary_residence_types = 'temporary_residence_types',
  termination_additional_payment_types = 'termination_additional_payment_types',
  termination_lump_payment_types = 'termination_lump_payment_types',
  termination_payment_time_units = 'termination_payment_time_units',
  termination_reason = 'employee_termination_reason',
  termination_salary_types = 'termination_salary_types',
  termination_type = 'employee_termination_type',
  termination_access_retention_approval_statuses = 'termination_access_retention_approval_statuses',
  termination_statuses = 'termination_statuses',
  timezones = 'timezones',
  budget_pool_configs = 'budget_pool_configs',
  budget_pool_config_types = 'budget_pool_config_types',
  budget_pool_config_statuses = 'budget_pool_config_statuses',
  uploaded_documents_confidentiality_choices = 'uploaded_documents_confidentiality_choices',
  yes_no_value_options = 'yes_no_value_options',
  yes_no_inverted_value_options = 'yes_no_inverted_value_options',
  yes_no_range_options = 'yes_no_range_options',
  yes_no_uppercase_value_options = 'yes_no_uppercase_value_options',
  kpi_calibration_factors = 'kpi_calibration_factors',
  document_template_rule_sql_query_databases = 'document_template_rule_sql_query_databases',
  employee_types = 'employee_types',
  performance_reviewed_employee_type = 'performance_reviewed_employee_type',
  kpi_to_assign_notification_categories = 'kpi_to_assign_notification_categories',
  kpi_to_approve_notification_categories = 'kpi_to_approve_notification_categories',
  benefit_types = 'benefit_types',
  benefit_providers = 'benefit_providers',
  employee_bonus_categories = 'employee_bonus_categories',
  employee_bonus_types = 'employee_bonus_types',
  employee_bonus_cycles = 'employee_bonus_cycles',
  employee_bonus_taxabilities = 'employee_bonus_taxabilities',
  employee_bonus_status = 'employee_bonus_status',
  interview_stage_scheduling_statuses = 'interview_stage_scheduling_statuses',
  vendors_names = 'vendors_names',
  vendors_revolut_entities = 'vendors_revolut_entities',
  vendors_legal_entities = 'vendors_legal_entities',
  vendors_statuses = 'vendors_statuses',
  data_dependencies = 'data_dependencies',
  data_objects_names = 'data_objects_names',
  user_feedback_statuses = 'user_feedback_statuses',
  user_feedback_topics = 'user_feedback_topics',
  users_status = 'users_status',
  user_types = 'user_types',
  performance_ratings = 'performance_ratings',
  entities_status = 'entities_status',
  pay_groups_status = 'pay_groups_status',
  contracting_country_status = 'contracting_country_status',
  interview_scorecard_template_types = 'interview_scorecard_template_types',
  functional_skills = 'functional_skills',
  company_values = 'company_values',
  meeting_categories = 'meeting_categories',
  time_off_request_periods = 'time_off_request_periods',
  time_off_request_approval_steps = 'time_off_request_approval_steps',
  time_off_request_approval_statuses = 'time_off_request_approval_statuses',
  time_off_request_statuses = 'time_off_request_statuses',
  time_off_regimes = 'time_off_regimes',
  time_off_regime_default_approvers = 'time_off_regime_default_approvers',
  time_off_regime_public_holidays = 'time_off_regime_public_holidays',
  time_off_balance_statuses = 'time_off_balance_statuses',
  time_off_balance_adjustment_types = 'time_off_balance_adjustment_types',
  time_off_balance_transaction_sources = 'time_off_balance_transaction_sources',
  time_off_balance_transaction_fields = 'time_off_balance_transaction_fields',
  time_off_request_changelog_fields = 'time_off_request_changelog_fields',
  time_off_toil_policies = 'time_off_toil_policies',
  time_off_policy_default_approvers = 'time_off_policy_default_approvers',
  time_off_policy_automatic_assignment_eligibility_statuses = 'time_off_policy_automatic_assignment_eligibility_statuses',
  days_of_the_week = 'days_of_the_week',
  public_holidays = 'public_holidays',
  months = 'months',
  quarter_1_months = 'quarter_1_months',
  quarter_2_months = 'quarter_2_months',
  quarter_3_months = 'quarter_3_months',
  quarter_4_months = 'quarter_4_months',
  time_off_regions = 'time_off_regions',
  time_off_policies = 'time_off_policies',
  active_time_off_policies = 'active_time_off_policies',
  time_off_policy_categories = 'time_off_policy_categories',
  time_off_policy_assignment_statuses = 'time_off_policy_assignment_statuses',
  time_off_policy_paid_options = 'time_off_policy_paid_options',
  time_off_policy_half_days_options = 'time_off_policy_half_days_options',
  time_off_balance_rounding_precisions = 'time_off_balance_rounding_precisions',
  time_off_balance_rounding_logics = 'time_off_balance_rounding_logics',
  time_off_balance_rounding_logics_human_readable = 'time_off_balance_rounding_logics_human_readable',
  time_off_balance_types = 'time_off_balance_types',
  time_off_units = 'time_off_units',
  time_off_accrual_periods = 'time_off_accrual_periods',
  time_off_accrual_periods_noun = 'time_off_accrual_periods_noun',
  time_off_policy_statuses = 'time_off_policy_statuses',
  time_off_first_accruals = 'time_off_first_accruals',
  time_off_balance_closing_periods = 'time_off_balance_closing_periods',
  time_off_carryover_limits = 'time_off_carryover_limits',
  time_off_request_changelog_statuses = 'time_off_request_changelog_statuses',
  time_off_balance_adjustment_fields_unlimited = 'time_off_balance_adjustment_fields_unlimited',
  time_off_accrual_periods_monthy_choices = 'time_off_accrual_periods_monthy_choices',
  summaries_list_approval_statuses = 'summaries_list_approval_statuses',
  summaries_list_shift_types = 'summaries_list_shift_types',
  scheduling_policies_accounting_periods = 'scheduling_policies_accounting_periods',
  scheduling_policies_statuses = 'scheduling_policies_statuses',
  scheduling_policies_names = 'scheduling_policies_names',
  shift_configuration_types = 'shift_configuration_types',
  shift_configuration_day_periods = 'shift_configuration_day_periods',
  shift_configuration_week_periods = 'shift_configuration_week_periods',
  shift_configuration_compensation_types = 'shift_configuration_compensation_types',
  shift_configuration_statuses = 'shift_configuration_statuses',
  shift_compensation_types = 'shift_compensation_types',
  sub_shift_types = 'sub_shift_types',
  job_postings = 'job_postings',
  job_postings_published = 'job_postings_published',
  job_postings_locations = 'job_postings_locations',
  job_postings_locations_type = 'job_postings_locations_type',
  all_job_postings_locations_type = 'all_job_postings_locations_type',
  postings_status = 'postings_status',
  posting_approval_status = 'posting_approval_status',
  automation_rule_when = 'automation_rule_when',
  automation_rule_action = 'automation_rule_action',
  automation_rule_join_condition = 'automation_rule_join_condition',
  automation_rule_condition = 'automation_rule_condition',
  automation_rule_status = 'automation_rule_status',
  email_templates_active = 'email_templates_active',
  email_templates = 'email_templates',
  employee_email_templates = 'employee_email_templates',
  employee_email_templates_active = 'employee_email_templates_active',
  candidate_origin_choices = 'candidate_origin_choices',
  hiring_stage_test_platforms = 'hiring_stage_test_platforms',
  candidate_timeline_categories = 'candidate_timeline_categories',
  promotion_stage_types = 'promotion_stage_types',
  promotion_stage_outcomes = 'promotion_stage_outcomes',
  promotion_status = 'promotion_status',
  promotion_pregression_tracks = 'progression_tracks',
  performance_calibration_flags = 'performance_calibration_flags',
  leadership_statuses = 'leadership_statuses',
  offer_form_placeholder_field_types = 'offer_form_placeholder_field_types',
  offer_form_placeholder_offer_sections = 'offer_form_placeholder_offer_sections',
  candidate_file_categories = 'candidate_file_categories',
  contract_salary_time_units = 'contract_salary_time_units',
  contract_salary_payment_frequencies = 'contract_salary_payment_frequencies',
  contract_notice_period_time_units = 'contract_notice_period_time_units',
  contract_work_modalities = 'contract_work_modalities',
  offer_templates = 'offer_templates',
  offer_form_statuses = 'offer_form_statuses',
  offer_form_approval_steps = 'offer_form_approval_steps',
  offer_form_signing_statuses = 'offer_form_signing_statuses',
  active_users = 'active_users',
  tenant_closure_reasons = 'tenant_closure_reasons',
  tenant_onboarding_checkpoints = 'tenant_onboarding_checkpoints',
  tenant_companies = 'tenant_companies',
  tenant_subdomains = 'tenant_subdomains',
  tenant_admins = 'tenant_admins',
  tenant_labels = 'tenant_labels',
  tenant_statuses = 'tenant_statuses',
  tenant_countries = 'tenant_countries',
  service_account_statuses = 'service_account_statuses',
  account_features = 'account_features',
  interview_round_states = 'interview_round_states',
  automatic_screening_check_sources = 'automatic_screening_check_sources',
  pip_lengths = 'pip_lengths',
  pip_hr_managers = 'pip_hr_managers',
  data_source_categories = 'data_source_categories',
  offboarding_managers = 'offboarding_managers',
  gdpr_policy_settings_categories = 'gdpr_policy_settings_categories',
  gdpr_triggering_events = 'gdpr_triggering_events',
  gdpr_time_units = 'gdpr_time_units',
  gdpr_countries = 'gdpr_countries',
  background_job_states = 'background_job_states',
  requisition_permissions = 'requisition_permissions',
  hireright_revolut_accounts = 'hireright_revolut_accounts',
  pay_cycle_starts = 'pay_cycle_starts',
  key_person_types_name = 'key_person_types_name',
  key_person_types_categories = 'key_person_types_categories',
  key_person_types_status = 'key_person_types_status',
  key_risk_profile_assignment_period = 'key_risk_profile_assignment_period',
  key_risk_profile_assignment_statuses = 'key_risk_profile_assignment_statuses',
  key_risk_profile_succession_plan_statuses = 'key_risk_profile_succession_plan_statuses',
  key_person_succession_plan_type = 'key_person_succession_plan_type',
  connection_types = 'connection_types',
  connection_permissions = 'connection_permissions',
  query_permissions = 'query_permissions',
  recruitment_groups = 'recruitment_groups',
  faq_statuses = 'faq_statuses',
  faq_topic_statuses = 'faq_topic_statuses',
  interview_scorecard_stage_types = 'interview_scorecard_stage_types',
  interview_feedback_salary_frequency_choices = 'interview_feedback_salary_frequency_choices',
  interview_feedback_right_to_work_choices = 'interview_feedback_right_to_work_choices',
  interview_stage_types = 'interview_stage_types',
  country_hiring_preference_employment_legal_risks = 'country_hiring_preference_employment_legal_risks',
  report_types = 'report_types',
  report_scheduling_types = 'report_scheduling_types',
  report_run_every = 'report_run_every',
  connection_names = 'connection_names',
  candidate_re_engagement_statuses = 'candidate_re_engagement_statuses',
  context_types = 'context_types',
  goal_content_types = 'goal_content_types',
  goal_statuses = 'goal_statuses',
  work_experience_companies = 'work_experience_companies',
  work_experience_positions = 'work_experience_positions',
  universities = 'universities',
  education_majors = 'education_majors',
  degrees = 'degrees',
  dashboard_related_kpis = 'dashboard_related_kpis',
  organisational_kpis = 'organisational_kpis',
  candidate_bulk_action_statuses = 'candidate_bulk_action_statuses',
  dashboard_rating_labels = 'dashboard_rating_labels',
  candidate_tags = 'candidate_tags',
  payroll_providers = 'payroll_providers',
  goal_timeline_categories = 'goal_timeline_categories',
  benchmarks_approvals_relationships = 'benchmarks_approvals_relationships',
  document_collections = 'document_collections',
  offer_template_statuses = 'offer_template_statuses',
}

export const selectorToUrl: { [key: string]: null | string } = {
  [selectorKeys.all_employees]: ROUTES.FORMS.EMPLOYEE.PROFILE,
  [selectorKeys.approved_role]: ROUTES.FORMS.ROLE.PREVIEW,
  [selectorKeys.all_employees]: ROUTES.FORMS.EMPLOYEE.PROFILE,
  [selectorKeys.approved_role]: ROUTES.FORMS.ROLE.GENERAL,
  [selectorKeys.audit]: null,
  [selectorKeys.audit_resolution]: null,
  [selectorKeys.audit_severity]: null,
  [selectorKeys.interview_scorecard_templates]:
    ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.PREVIEW,
  [selectorKeys.automatic]: null,
  [selectorKeys.business_debt_percent]: null,
  [selectorKeys.company_goals]: ROUTES.FORMS.COMPANY_GOALS.GENERAL,
  [selectorKeys.company_kpis]: ROUTES.FORMS.KPI.PREVIEW,
  [selectorKeys.communication_groups]: ROUTES.FORMS.COMMUNICATION_GROUPS.VIEW.GROUP,
  [selectorKeys.contract_types]: null,
  [selectorKeys.cycle]: null,
  [selectorKeys.cycle_offsets]: null,
  [selectorKeys.department]: ROUTES.FORMS.DEPARTMENT.SUMMARY,
  [selectorKeys.employee]: ROUTES.FORMS.EMPLOYEE.PROFILE,
  [selectorKeys.employee_pending]: ROUTES.FORMS.EMPLOYEE.PROFILE,
  [selectorKeys.employee_requisitions_status]: null,
  [selectorKeys.employee_scorecards_types]: null,
  [selectorKeys.employee_status]: null,
  [selectorKeys.employee_status_pending]: null,
  [selectorKeys.employees_for_teams]: ROUTES.FORMS.EMPLOYEE.PROFILE,
  [selectorKeys.entity]: null,
  [selectorKeys.functions]: ROUTES.FORMS.FUNCTION.SUMMARY,
  [selectorKeys.legal_sexes]: null,
  [selectorKeys.groups]: null,
  [selectorKeys.home_task]: null,
  [selectorKeys.job_description]: null,
  [selectorKeys.job_description_category]: null,
  [selectorKeys.kpi_goals]: null,
  [selectorKeys.kpi_names]: ROUTES.FORMS.KPI.PREVIEW,
  [selectorKeys.kpi_performance_percent]: null,
  [selectorKeys.kpi_statuses]: null,
  [selectorKeys.language]: null,
  [selectorKeys.location]: null,
  [selectorKeys.manager]: ROUTES.FORMS.EMPLOYEE.PROFILE,
  [selectorKeys.none]: null,
  [selectorKeys.performance]: null,
  [selectorKeys.permissions]: null,
  [selectorKeys.priority]: null,
  [selectorKeys.requisitions_priority]: null,
  [selectorKeys.requisitions_status]: null,
  [selectorKeys.requisitions_title]: ROUTES.FORMS.REQUISITION.ROLE,
  [selectorKeys.resolution_date_time]: null,
  [selectorKeys.review_cycles]: null,
  [selectorKeys.reviewCycleStatuses]: null,
  [selectorKeys.revoluters_all]: null,
  [selectorKeys.roadmap_priority]: null,
  [selectorKeys.roadmap_progress_percent]: null,
  [selectorKeys.roadmap_stage]: null,
  [selectorKeys.roadmap_status]: null,
  [selectorKeys.role]: ROUTES.FORMS.ROLE.PREVIEW,
  [selectorKeys.roles_status]: null,
  [selectorKeys.scorecard]: null,
  [selectorKeys.scorecard_category]: null,
  [selectorKeys.seniority]: null,
  [selectorKeys.skill_complete]: ROUTES.FORMS.SKILL.GENERAL,
  [selectorKeys.skills]: ROUTES.FORMS.SKILL.GENERAL,
  [selectorKeys.skillset]: ROUTES.FORMS.SKILL.GENERAL,
  [selectorKeys.skillsets_status]: null,
  [selectorKeys.slack]: null,
  [selectorKeys.specialisations]: ROUTES.FORMS.SPECIALISATIONS.PREVIEW,
  [selectorKeys.approved_specialisations]: ROUTES.FORMS.SPECIALISATIONS.PREVIEW,
  [selectorKeys.team]: ROUTES.FORMS.TEAM.SUMMARY,
  [selectorKeys.team_owner]: ROUTES.FORMS.EMPLOYEE.PROFILE,
  [selectorKeys.team_projects]: null,
  [selectorKeys.offer_templates]: ROUTES.FORMS.OFFER_TEMPLATE.GENERAL,
  [selectorKeys.document_templates]: ROUTES.FORMS.DOCUMENT_TEMPLATE.PREVIEW,
  [selectorKeys.email_templates_active]: ROUTES.FORMS.EMAIL_TEMPLATE.PREVIEW,
  [selectorKeys.email_templates]: ROUTES.FORMS.EMAIL_TEMPLATE.PREVIEW,
}

export const selectorToCreateURL: { [key: string]: null | string } = {
  [selectorKeys.approved_role]: ROUTES.FORMS.ROLE.PREVIEW,
  [selectorKeys.interview_scorecard_templates]:
    ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.SETTINGS,
  [selectorKeys.approved_role]: ROUTES.FORMS.ROLE.GENERAL,
  [selectorKeys.communication_groups]: ROUTES.FORMS.COMMUNICATION_GROUPS.EDIT,
  [selectorKeys.company_goals]: ROUTES.FORMS.COMPANY_GOALS.GENERAL,
  [selectorKeys.company_kpis]: ROUTES.FORMS.KPI.PREVIEW,
  [selectorKeys.department]: ROUTES.FORMS.DEPARTMENT.GENERAL,
  [selectorKeys.functions]: ROUTES.FORMS.FUNCTION.SETTINGS,
  [selectorKeys.kpi_names]: ROUTES.FORMS.KPI.GENERAL,
  [selectorKeys.role]: ROUTES.FORMS.ROLE.GENERAL,
  [selectorKeys.skill_complete]: ROUTES.FORMS.SKILL.GENERAL,
  [selectorKeys.skills]: ROUTES.FORMS.SKILL.GENERAL,
  [selectorKeys.skillset]: ROUTES.FORMS.SKILL.GENERAL,
  [selectorKeys.specialisations]: ROUTES.FORMS.SPECIALISATIONS.GENERAL,
  [selectorKeys.team]: ROUTES.FORMS.TEAM.SETTINGS,
}

export const selectorToItemName: { [key: string]: null | string } = {
  [selectorKeys.approved_role]: 'role',
  [selectorKeys.interview_scorecard_templates]: 'scorecard',
  [selectorKeys.approved_role]: 'role',
  [selectorKeys.company_goals]: 'company goal',
  [selectorKeys.communication_groups]: 'group',
  [selectorKeys.company_kpis]: 'company KPI',
  [selectorKeys.department]: 'department',
  [selectorKeys.functions]: 'function',
  [selectorKeys.kpi_names]: 'KPI',
  [selectorKeys.role]: 'role',
  [selectorKeys.skill_complete]: 'skill',
  [selectorKeys.skills]: 'skill',
  [selectorKeys.skillset]: 'skill',
  [selectorKeys.specialisations]: 'specialisation',
  [selectorKeys.team]: 'team',
}

export enum PerformanceTimeRange {
  week = 'week',
  month = 'month',
  day = 'day',
  quarter = 'quarter',
}

export enum EntityTypes {
  company = 'company',
  department = 'department',
  team = 'team',
  function = 'function',
  employee = 'employee',
  role = 'role',
  specialisation = 'specialisation',
  // sometimes BE uses following because that's exact table names
  teams = 'teams',
  employees = 'employees',
}
