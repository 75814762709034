import { api, apiWithoutHandling } from '@src/api'
import {
  GetRequestData,
  Id,
  RequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import {
  GoalKpiDetails,
  GoalPreviewInterface,
  GoalsInterface,
  GoalsTimelineEventInterface,
  GoalWeightMode,
  ManageGoalFields,
  ParentGoalSelector,
} from '@src/interfaces/goals'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useFetch } from '@src/utils/reactQuery'
import { API, PerformanceTimeRange } from '@src/constants/api'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { getCommentsAPI } from './comments'

export const getMonthsByRange = (range: PerformanceTimeRange, increment: number = 0) => {
  if (range === PerformanceTimeRange.week) {
    return `${-3 + 3 * increment},${0 + 3 * increment}` // -6,-3|-3,0|0,3 ;
  }
  if (range === PerformanceTimeRange.day) {
    return `${increment},${increment}` // 1,1|0,0|2,2
  }

  return `${-11 + 12 * increment},${1 + 12 * increment}` // -23,-11|-11,1|1,13
}

// comment field used only to provide justification for some updates
export const goalsRequests: RequestInterfaceNew<
  GoalPreviewInterface & { comment?: string }
> = {
  get: async ({ id }) => {
    return api.get(`${API.GOALS}/${id}`)
  },
  update: async (data, { id }) => apiWithoutHandling.patch(`${API.GOALS}/${id}`, data),
  delete: async ({ id }) => api.delete(`${API.GOALS}/${id}`),
  submit: async data => apiWithoutHandling.post(API.GOALS, data),
}

export const createDraftGoal = (data: {
  owner: Id
  is_company?: boolean
  object_id?: number
  content_type?: Id
}) => apiWithoutHandling.post<Id & Partial<GoalsInterface>>(API.GOALS, data)

export type GoalsInterfaceWithChildren = GoalsInterface & {
  children?: GoalsInterface
}

export type GoalsStats = { overall_progress: number }

export const goalsListTableRequests: TableRequestInterface<
  GoalsInterfaceWithChildren,
  GoalsStats
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get<GetRequestData<GoalsInterface>>(
      `${API.GOALS}`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v1',
    ),
  getStats: async ({ filters, page }) =>
    apiWithoutHandling.get(
      `${API.GOALS}/stats`,
      {
        params: filterSortPageIntoQuery(undefined, filters, page),
      },
      'v1',
    ),
}

export const goalsTimelineTableRequests = (
  id: number,
): TableRequestInterface<GoalsTimelineEventInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.GOALS}/${id}/timeline`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v1',
    ),
})

export const useGetGoalParentSelector = (
  contentType?: 'employee_goal' | 'team_goal' | 'department_goal',
) => {
  return useFetch<ParentGoalSelector[]>(
    contentType ? `${API.GOALS}/parentSelector?type=${contentType}` : null,
  )
}

export const useGetGoal = (id?: number) => {
  return useFetch<GoalPreviewInterface>({
    url: id ? `${API.GOALS}/${id}` : null,
    queryOptions: {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  })
}

export const fetchGoalGraph = async (
  id: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
) => {
  return apiWithoutHandling.get<PerformanceChartCycles>(
    `${API.GOALS}/${id}/performanceCycles`,
    {
      params: {
        date_partitioning: range,
        months: getMonthsByRange(range),
        review_cycle__offset: cycleOffset,
      },
    },
  )
}

export type SupportedEntityGraphPath = 'company' | 'employees' | 'teams' | 'departments'

export const fetchEntityGoalsGraph =
  (entityType: SupportedEntityGraphPath) =>
  async (
    id: string, // entity id,
    range: PerformanceTimeRange = PerformanceTimeRange.week,
    cycleOffset?: string,
  ) => {
    const basePath = entityType === 'company' ? `/${entityType}` : `/${entityType}/${id}`

    return apiWithoutHandling.get<PerformanceChartCycles>(
      `/${basePath}/goalPerformance`,
      {
        params: {
          date_partitioning: range,
          months: getMonthsByRange(range),
          review_cycle__offset: cycleOffset,
        },
      },
    )
  }

export const approveGoal = (id: number) => {
  return apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/approve`)
}

export const bulkApproveGoals = (goals: Array<{ id: GoalsInterface['id'] }>) => {
  return apiWithoutHandling.post<never>(`${API.GOALS}/bulkApprove`, { goals })
}

export const requestChange = (id: number, reason: string) => {
  return apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/reject`, {
    comment: reason,
  })
}

export const useGetGoalApprovalFlow = (id: number | undefined) => {
  return useFetch<ApprovalFlowResponse>(id ? `${API.GOALS}/${id}/approvals` : null)
}

export const useGetGoals = (query?: string) =>
  useFetch<GetRequestData<GoalsInterface>>(query ? `${API.GOALS}${query}` : null)

export const calibrateGoal = (
  id: number,
  data: {
    calibrated_progress: number
    comment: string
    review_cycle: Id<string>
  },
) => apiWithoutHandling.patch(`${API.GOALS}/${id}`, data)

export type ManageGoalEntityDefinition =
  | {
      content_type: { id: number }
      object_id: number
    }
  | {
      is_company: true
    }

export type ManageGoalsPayload = {
  goal_weight_mode: { id: GoalWeightMode }
  goals: ManageGoalFields[]
  review_cycle: Id<string>
} & ManageGoalEntityDefinition

export const manageGoals = (payload: ManageGoalsPayload) =>
  apiWithoutHandling.post(`${API.GOALS}/manageGoals`, payload)

export const refreshGoalProgress = (id: number) =>
  apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/refresh`)

export const archiveGoal = (id: number) =>
  apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/archive`)

export const unarchiveGoal = (id: number) =>
  apiWithoutHandling.post<GoalsInterface>(`${API.GOALS}/${id}/unarchive`)

export const deleteGoal = (id: number) =>
  apiWithoutHandling.delete<GoalsInterface>(`${API.GOALS}/${id}`)

export const getGoalCommentsAPI = (id: number) =>
  getCommentsAPI({ baseUrl: `${API.GOALS}/${id}/comments` })

export const submitGoalNotification = (id: number, category: string, cycleId: number) => {
  return apiWithoutHandling.post(`${API.GOAL_NOTIFICATIONS}/${category}/${id}/submit`, {
    cycle: { id: cycleId },
  })
}

export const useGoalTargetPerformanceGraph = ({
  id,
  range = PerformanceTimeRange.week,
  cycleOffset,
  increment = 0,
}: {
  id?: number
  increment?: number
  range?: PerformanceTimeRange
  cycleOffset?: string
}) => {
  return useFetch<PerformanceChartCycles>({
    url: id ? `${API.KPIS}/${id}/performanceCycles` : null,
    withoutHandling: true,
    params: {
      params: {
        date_partitioning: range,
        months: getMonthsByRange(range, increment),
        review_cycle__offset: cycleOffset,
      },
    },
  })
}

export const getGoalsTargets: RequestInterface<GoalKpiDetails>['getItems'] = async ({
  sortBy,
  filters,
  page,
}) =>
  api.get(
    `${API.KPIS}/goalKPIs`,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )
