import { IdAndOptionalName, Statuses } from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'

export const getCommonFilters = ({
  reviewCycle,
  cycleFilterKey = 'cycle__id',
}: {
  reviewCycle?: IdAndOptionalName<string | number>
  cycleFilterKey?: 'cycle__id'
} = {}): FilterByInterface[] => {
  return [
    reviewCycle
      ? {
          filters: [
            {
              id: String(reviewCycle.id),
              name: reviewCycle.name || String(reviewCycle.id),
            },
          ],
          columnName: cycleFilterKey,
          nonResettable: true,
        }
      : null,
    {
      columnName: 'goals_related',
      filters: [{ id: 'true', name: 'true' }],
      nonResettable: true,
    },
    {
      filters: [
        { name: Statuses.pending, id: Statuses.pending },
        { name: Statuses.approved, id: Statuses.approved },
        { name: Statuses.future, id: Statuses.future },
        { name: Statuses.completed, id: Statuses.completed },
        { name: Statuses.requires_changes, id: Statuses.requires_changes },
      ],
      columnName: 'target_status',
    },
  ].filter(Boolean)
}
