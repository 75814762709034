import { useState } from 'react'
import { isArray } from 'lodash'

import { ImportDataCategory } from '@src/interfaces/bulkDataImport'
import { getImportFileTemplate, importFile } from '@src/api/bulkDataImport'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { handleError } from '@src/api'

import { saveFile } from '.'

export const useImportFile = (
  endpoint: string,
  path: string,
  name: string,
  category: ImportDataCategory,
  params?: Record<string, string>,
) => {
  const [importPending, setImportPending] = useState(false)
  const [downloadTemplatePending, setDownloadTemplatePending] = useState(false)
  const [error, setError] = useState<string>()

  const onFileUpload = async (file: File | File[] | null) => {
    if (file) {
      setImportPending(true)
      setError(undefined)

      try {
        const response = await importFile(
          isArray(file) ? file[0] : file,
          endpoint,
          category,
        )
        navigateReplace(pathToUrl(path, { id: response.data.id }))
      } catch (e) {
        if (e?.response?.data?.file) {
          setError(e?.response?.data?.file as string)
        } else {
          handleError(e)
        }
        setImportPending(false)
      }
    }
  }

  const onDownloadTemplate = async () => {
    setDownloadTemplatePending(true)
    try {
      saveFile(await getImportFileTemplate(endpoint, params), name)
    } finally {
      setDownloadTemplatePending(false)
    }
  }

  return {
    importPending,
    downloadTemplatePending,
    onFileUpload,
    onDownloadTemplate,
    error,
  }
}
