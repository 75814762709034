import React from 'react'
import { Avatar, Icon, IconName, Item, Token, VStack, Widget } from '@revolut/ui-kit'

import { PageBody } from '@components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'

type NavigationItemProps = {
  icon: IconName
  title: string
  subtitle: string
  url: string
}
const NavigationItem = ({ icon, title, subtitle, url }: NavigationItemProps) => (
  <Item use="label" onClick={() => navigateTo(url)}>
    <Item.Avatar>
      <Avatar useIcon={icon} color={Token.color.blue} />
    </Item.Avatar>
    <Item.Content>
      <Item.Title>{title}</Item.Title>
      <Item.Description>{subtitle}</Item.Description>
    </Item.Content>
    <Item.Side>
      <Icon name="ChevronRight" color={Token.color.greyTone20} />
    </Item.Side>
  </Item>
)

export const SelectBulkUploadFlow = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Bulk import data"
        subtitle="Upload a .xlsx or .csv file to import your employees"
        backUrl={ROUTES.PEOPLE.EMPLOYEES}
      />
      <PageBody>
        <Widget>
          <VStack>
            <NavigationItem
              icon="AddContact"
              title="Create multiple employees"
              subtitle="Use this to bulk create new employees"
              url={pathToUrl(ROUTES.APPS.EMPLOYEES.BULK_UPDATE.IMPORT, undefined, {
                entity: 'employees',
              })}
            />
            <NavigationItem
              icon="ArrowExchange"
              title="Update existing employees’ information"
              subtitle="Use this to update many fields for many employees, these changes are done right away"
              url={ROUTES.APPS.EMPLOYEES.BULK_UPDATE.IMPORT}
            />
            <NavigationItem
              icon="CashIncome"
              title="Create new contracts"
              subtitle="Use this to update fields such as compensation and employment type"
              url={pathToUrl(ROUTES.APPS.EMPLOYEES.BULK_UPDATE.IMPORT, undefined, {
                entity: 'contracts',
              })}
            />
          </VStack>
        </Widget>
      </PageBody>
    </PageWrapper>
  )
}
