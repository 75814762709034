import { api, apiWithoutHandling } from '@src/api/index'
import { filterSortPageIntoQuery, groupFiltersIntoURLQuery } from '@src/utils/table'
import { TalentStatsInterface } from '@src/interfaces/functions'
import { GetRequestData, RequestInterface } from '@src/interfaces'
import { API } from '@src/constants/api'
import {
  TalentBulkGradeUpdateInterface,
  TalentPerformanceBulkGradeUpdateResponse,
  TalentPerformanceCommentInterface,
  TalentPerformanceDelegateInterface,
  TalentPerformanceDelegateResponse,
  TalentPerformanceInterface,
  TalentPerformanceStatsInterface,
  TalentPerformanceRelationsInterface,
} from '@src/interfaces/talent/performance'
import { getCommentsAPI } from '@src/api/comments'
import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import { useFetch } from '@src/utils/reactQuery'
import produce from 'immer'

export const getTalentStats = (columnName: string, value?: string) => {
  return api.get<TalentStatsInterface>(`/talent/stats`, {
    params: filterSortPageIntoQuery(
      [],
      [
        {
          columnName,
          filters: [{ id: `${value}`, name: `${value}` }],
        },
      ],
    ),
  })
}

export const useGetCalibrarionStats = (filters: FilterByInterface[]) => {
  return useFetch<TalentPerformanceStatsInterface>(
    `${API.PERFORMANCE}/employeesCalibratedGrades/stats`,
    'v1',
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
  )
}

export const talentPerformanceRequests: RequestInterface<
  TalentPerformanceInterface,
  TalentPerformanceStatsInterface
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.PERFORMANCE}/employeesCalibratedGrades`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: async ({ filters, page }) =>
    api.get(`${API.PERFORMANCE}/employeesCalibratedGrades/stats`, {
      params: filterSortPageIntoQuery(undefined, filters, page),
    }),
  getItem: async id => api.get(`${API.PERFORMANCE}/employeesCalibratedGrades/${id}`),
  patchItem: async (data, id) =>
    api.patch(`${API.PERFORMANCE}/employeesCalibratedGrades/${id}`, data),
  deleteItem: async id =>
    api.delete(`${API.PERFORMANCE}/employeesCalibratedGrades/${id}`),
  postItem: async data => api.post(`${API.PERFORMANCE}/employeesCalibratedGrades`, data),
  getExport: async (exportType, filterQuery) =>
    api.get(`${API.PERFORMANCE}/employeesCalibratedGrades/${exportType}`, {
      params: filterQuery,
      responseType: 'blob',
    }),
}

export interface TalentPerformanceTableItemInterface extends TalentPerformanceInterface {
  isSeparator?: boolean
}

export const getTalentPerformanceItemsWithSeparators =
  () =>
  async ({ sortBy, filters, page }: FetchDataQueryInterface) => {
    const response = await api.get<GetRequestData<TalentPerformanceTableItemInterface>>(
      `${API.PERFORMANCE}/employeesCalibratedGrades`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    )

    // in the calibration tables we need additional lines-separators, to visually split groups with same grades
    // these lines-separators need to have the same fields as an average table item to not break the table
    //
    // in calibration we also have drag-n-drop feature, which does some sorting on the go,
    // so had to introduce a hack with id numbers for these separator lines (commented below)
    return {
      ...response,
      data: {
        ...response.data,
        results: produce(response.data.results, draft => {
          // for loop instead of forEach here bc we're adding lines to the data results on the go,
          // but forEach doesn't update the array length and stops on the last item index of the original array
          for (let i = 0; i < draft.length; i++) {
            const rowData = draft[i]
            const prevRowData = draft[i - 1]
            const currentRowGrade = rowData.function_owner_grade_suggestion?.id
            const prevRowGrade = prevRowData?.function_owner_grade_suggestion?.id

            if (currentRowGrade !== prevRowGrade) {
              draft.splice(i, 0, {
                ...rowData,
                // tried to put ids for separators as -1 or 0, but when drag-n-dropping items it seems like some sorting
                // applies under the hood and pushes these lines to the top of the table
                // bc of this behaviour, had to assign an id that won't be the same as any other line in any case,
                // and also won't be affected by sorting and remain on the same position
                // string seem to be not as stable as this solution and also require unnecessary type coverage on the Row level
                id: rowData.id - 0.5,
                isSeparator: true,
              })
            }
          }
        }),
      },
    }
  }

export const updateTalentRank = (
  id: number | string,
  beforeRowId: number,
  afterRowId: number,
  filters: FilterByInterface[],
) =>
  api.put(
    `${API.PERFORMANCE}/employeesCalibratedGrades/${id}/rankUpdate`,
    {
      item_before_id: beforeRowId,
      item_after_id: afterRowId,
    },
    { params: groupFiltersIntoURLQuery(filters) },
  )

export const updateCalibratedGrade = (
  id: number,
  data: Partial<TalentPerformanceInterface>,
  filters: FilterByInterface[],
) =>
  api.patch<TalentPerformanceInterface>(
    `${API.PERFORMANCE}/employeesCalibratedGrades/${id}`,
    data,
    { params: groupFiltersIntoURLQuery(filters) },
  )

export const getTalentCommentsAPI = (id: number | null) =>
  getCommentsAPI({
    baseUrl: `${API.PERFORMANCE}/employeesCalibratedGrades/${id}/comments`,
  })

export const getCommentsCount = (filters: FilterByInterface[]) =>
  api.get<TalentPerformanceCommentInterface[]>(
    `${API.PERFORMANCE}/employeesCalibratedGrades/comments`,
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
  )

export const bulkGradeUpdate = (
  data: TalentBulkGradeUpdateInterface[],
  filters?: FilterByInterface[],
) => {
  return apiWithoutHandling.put<{ results: TalentPerformanceBulkGradeUpdateResponse[] }>(
    `${API.PERFORMANCE}/employeesCalibratedGrades/bulkGradeUpdate`,
    data,
    {
      params: groupFiltersIntoURLQuery(filters),
    },
  )
}

export const delegateCalibration = (
  data: TalentPerformanceDelegateInterface,
  filters: FilterByInterface[],
) => {
  return apiWithoutHandling.put<{ results: TalentPerformanceDelegateResponse[] }>(
    `${API.PERFORMANCE}/employeesCalibratedGrades/delegateCalibration`,
    data,
    { params: filterSortPageIntoQuery(undefined, filters) },
  )
}

export const useGetTalentByComment = (itemId: number) =>
  useFetch<TalentPerformanceRelationsInterface>({
    url: itemId ? `${API.PERFORMANCE}/employeesCalibratedGrades/comment/${itemId}` : null,
    withoutHandling: true,
  })

export const getTalentByComment = (itemId: number) =>
  api.get<TalentPerformanceRelationsInterface>(
    `${API.PERFORMANCE}/employeesCalibratedGrades/comment/${itemId}`,
  )
