import React, { useEffect, useRef, useState, forwardRef, useMemo } from 'react'
import {
  Flex,
  Text,
  Icon,
  Token,
  useTooltip,
  Box,
  Tooltip,
  TextSkeleton,
  useStatusPopup,
  Popup,
  Header,
  StatusPopup,
  Footnote,
  Button,
  MoreBar,
  IconName,
  ProgressCircle,
  Cell,
  VStack,
} from '@revolut/ui-kit'
import { get } from 'lodash'

import RadioSelectInput, {
  createNewKey,
} from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { ColumnInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import EditableCell from '@src/components/Table/AdvancedCells/EditableCell/EditableCell'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import { selectorKeys } from '@src/constants/api'
import {
  applyImportSession,
  bulkDeleteImportSessionRows,
  bulkEditImportSessionRows,
  createImportFromEntities,
  getImportSessionBulkItems,
  useGetImportSessionData,
  useGetImportSessionDataStats,
} from '@src/api/bulkDataImport'
import { IdAndName } from '@src/interfaces'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { TableActionsProps } from './GenericEditableTable'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import FilterSelect from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import { AccessGroupSelectorOption } from '@src/components/AccessGroup/AccessGroupSelectorOption'
import { formatPercentage } from '@src/utils/format'

const selectorQueryOptions = { cacheTime: Infinity, staleTime: Infinity }

export type GenericEditableTableOnChange = (
  rowId: number,
  value: unknown,
  field: string,
) => void

export type GenericEditableTableColumn<T> = (
  onChange: GenericEditableTableOnChange,
  isPreview?: boolean,
) => ColumnInterface<ImportInterface<T>>

type RadioSelectInputCellProps<T> = {
  onChange: GenericEditableTableOnChange
  selector: selectorKeys
  field: string
  fieldName?: string
  data: ImportInterface<T>
  useNameField?: boolean
  onCreateNewClick?: (rowId: number, onChangeAction: (entity: IdAndName) => void) => void
  customLabel?: React.ReactNode
}

interface SelectCellProps {
  open: boolean
  setOpen: (open: boolean) => void
  children: React.ReactNode
}

export const SelectCell = forwardRef<HTMLDivElement, SelectCellProps>(
  ({ open, setOpen, children }, ref) => {
    return (
      <Flex
        onClick={() => setOpen(!open)}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        use="button"
        type="button"
        color="inherit"
        ref={ref}
      >
        <Text textOverflow="ellipsis" overflow="hidden">
          {children}
        </Text>
        <Icon
          color={Token.color.greyTone50}
          name={open ? 'ChevronUp' : 'ChevronDown'}
          size={16}
        />
      </Flex>
    )
  },
)

export const RadioSelectInputCell = <T,>({
  data,
  onChange,
  selector,
  field,
  fieldName,
  useNameField,
  onCreateNewClick,
  customLabel,
}: RadioSelectInputCellProps<T>) => {
  return (
    <CellWithError data={data} field={field}>
      <RadioSelectInput
        onChange={option => {
          if (option?.id === createNewKey) {
            onCreateNewClick?.(data.id, newEntity => {
              onChange(data.id, useNameField ? newEntity?.name : newEntity, field)
            })
          } else {
            onChange(data.id, useNameField ? option?.name : option, field)
          }
        }}
        selector={selector}
        useQuery
        useQueryOptions={selectorQueryOptions}
        renderInput={(open, setOpen, ref) => (
          <SelectCell open={open} setOpen={setOpen} ref={ref}>
            {customLabel ||
              get(data.data, `${field}.name`) ||
              get(data.data, field) ||
              `Select ${fieldName || field}`}
          </SelectCell>
        )}
        showCreateNewButton={!!onCreateNewClick}
        refetchOnOpen
      />
    </CellWithError>
  )
}

interface TextCellProps<T> {
  data: ImportInterface<T>
  onChange: (rowId: number, value: string | number, field: string) => void
  field: string
}

export const TextCell = <T,>({ data, onChange, field }: TextCellProps<T>) => {
  const dataField = get(data.data, field)

  const [value, setValue] = useState<string | number>(dataField || '')

  useEffect(() => {
    if (!!dataField && dataField !== value) {
      setValue(dataField)
    }
  }, [dataField])

  return (
    <CellWithError data={data} field={field}>
      <EditableCell
        type={TableCellInputType.text}
        value={value}
        onChange={val => setValue(val)}
        onBlur={val => {
          const newVal = typeof val === 'string' ? val.trim() : val
          setValue(newVal)
          onChange(data.id, newVal, field)
        }}
        hidePencil
      />
    </CellWithError>
  )
}

export type MultiSelectInputCellProps<T> = {
  label?: string
  value?: { id: number | string; name: string }[]
  onChange: GenericEditableTableOnChange
  selector: selectorKeys
  field: string
  fieldName?: string
  data: ImportInterface<T>
}

export const MultiSelectInputCell = <T,>({
  data,
  onChange,
  selector,
  field,
  fieldName,
  label,
  value,
}: MultiSelectInputCellProps<T>) => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  return (
    <CellWithError data={data} field={field}>
      <SelectCell open={open} setOpen={setOpen} ref={anchorRef}>
        {label ||
          get(data.data, field)
            ?.map((opt: unknown) =>
              typeof opt === 'object' && opt != null && 'name' in opt ? opt.name : null,
            )
            .filter(Boolean)
            .join(', ') ||
          `Select ${fieldName || field}`}
      </SelectCell>
      <FilterSelect<{ id: string | number; name: string; description?: string }>
        value={value || get(data.data, field)}
        onChange={options => onChange(data.id, options, field)}
        selector={selector}
        open={open}
        onClose={() => setOpen(false)}
        anchorRef={anchorRef}
        useQuery
        useQueryOptions={selectorQueryOptions}
        renderOption={option => <AccessGroupSelectorOption {...option.value} />}
        width={500}
      />
    </CellWithError>
  )
}

interface CellWithErrorProps<T> {
  data: ImportInterface<T>
  field: string
  children: React.ReactNode
}

const CellWithError = <T,>({ data, field, children }: CellWithErrorProps<T>) => {
  const tooltip = useTooltip()

  const errors = get(data.errors, field)?.join('\n')
  const loading = get(data.loading, field)

  if (loading) {
    return <TextSkeleton />
  }

  return (
    <Flex alignItems="center" gap="s-8" {...tooltip.getAnchorProps()}>
      <Box
        flex="1"
        color={errors ? Token.color.error : undefined}
        style={{ overflowX: 'hidden' }}
      >
        {children}
      </Box>
      <Tooltip {...tooltip.getTargetProps()} hidden={!errors}>
        {errors}
      </Tooltip>
    </Flex>
  )
}

interface BulkEditActionProps extends TableActionsProps {
  buttonIcon: IconName
  field: string
  selector: selectorKeys
  label?: string
}

export const BulkEditAction = ({
  buttonIcon,
  field,
  selector,
  sessionData,
  getSelectedItems,
  refreshTableState,
  apiEndpoint,
  someSelected,
  label,
}: BulkEditActionProps) => {
  const visibleLabel = label || field

  const [bulkEditPending, setBulkEditPending] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [option, setOption] = useState<IdAndName | null>(null)
  const statusPopup = useStatusPopup()

  const onAssign = () => {
    if (!sessionData?.id) {
      return
    }

    const items = getSelectedItems()

    setBulkEditPending(true)

    bulkEditImportSessionRows(apiEndpoint, sessionData.id, items, {
      [field]: option?.name || null,
    })
      .then(() => {
        setPopupOpen(false)
        setBulkEditPending(false)
        refreshTableState()
        setOption(null)
      })
      .catch(error => {
        setBulkEditPending(false)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to update</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  return (
    <>
      <MoreBar.Action
        useIcon={buttonIcon}
        disabled={!someSelected}
        onClick={() => setPopupOpen(true)}
      >
        Change {visibleLabel}
      </MoreBar.Action>

      <Popup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        variant="bottom-sheet"
        preventUserClose={bulkEditPending}
      >
        <Header variant="bottom-sheet">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Assign {visibleLabel}</Header.Title>
        </Header>

        <RadioSelectInput
          label={`Select ${visibleLabel}`}
          value={option}
          onChange={setOption}
          selector={selector}
        />

        <Popup.Actions>
          <Footnote>This will replace the {visibleLabel} already assigned</Footnote>
          <Button
            onClick={onAssign}
            elevated
            pending={bulkEditPending}
            disabled={!option}
          >
            Assign
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

interface BulkDeleteButtonProps extends TableActionsProps {}

export const BulkDeleteButton = ({
  sessionData,
  getSelectedItems,
  refreshTableState,
  apiEndpoint,
  someSelected,
}: BulkDeleteButtonProps) => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [deleteInBulkPending, setDeleteInBulkPending] = useState(false)

  const statusPopup = useStatusPopup()

  return (
    <>
      <MoreBar.Action
        useIcon="Delete"
        disabled={!someSelected}
        onClick={() => setDeleteConfirmationOpen(true)}
        variant="negative"
      >
        Delete
      </MoreBar.Action>

      <ConfirmationDialog
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onReject={() => setDeleteConfirmationOpen(false)}
        loading={deleteInBulkPending}
        onConfirm={async () => {
          if (!sessionData) {
            return
          }

          const items = getSelectedItems()

          try {
            setDeleteInBulkPending(true)
            await bulkDeleteImportSessionRows(apiEndpoint, sessionData.id, items)
            refreshTableState()
            setDeleteConfirmationOpen(false)
          } catch (error) {
            statusPopup.show(
              <StatusPopup variant="error">
                <StatusPopup.Title>Failed to remove items</StatusPopup.Title>
                <StatusPopup.Description>
                  {getStringMessageFromError(error)}
                </StatusPopup.Description>
                <StatusPopup.Actions>
                  <Button onClick={() => statusPopup.hide()} elevated>
                    Close
                  </Button>
                </StatusPopup.Actions>
              </StatusPopup>,
            )
          } finally {
            setDeleteInBulkPending(false)
          }
        }}
        label="Are you sure you want to remove these items?"
        body=""
        yesMessage="Confirm"
        noMessage="Cancel"
      />
    </>
  )
}

interface BulkEditExistingEntitiesActionProps extends TableActionsProps {
  buttonIcon: IconName
  field: string
  selector: selectorKeys
  sessionRoute: string
  label?: string
  fieldsToBulkEdit?: string[]
}

export const BulkEditExistingEntitiesAction = ({
  buttonIcon,
  field,
  selector,
  sessionRoute,
  getSelectedItems,
  apiEndpoint,
  someSelected,
  label,
  refreshTableState,
  fieldsToBulkEdit,
}: BulkEditExistingEntitiesActionProps) => {
  const statusPopup = useStatusPopup()

  const visibleLabel = label || field

  const [bulkEditPending, setBulkEditPending] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [option, setOption] = useState<IdAndName | null>(null)
  const [sessionId, setSessionId] = useState<number>()
  const [actionState, setActionState] = useState<
    'check-ready-for-review' | 'check-stats' | 'apply-changes'
  >()
  const [progress, setProgress] = useState(0)

  const showErrorsOccuredPopup = () => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>We found some errors</StatusPopup.Title>
        <StatusPopup.Description>
          Please review and correct, or proceed to skip
        </StatusPopup.Description>
        <StatusPopup.Actions>
          <Button
            onClick={() => {
              statusPopup.hide()
              navigateTo(pathToUrl(sessionRoute, { id: sessionId, type: field }))
            }}
          >
            Fix errors
          </Button>
          <Button variant="secondary" onClick={statusPopup.hide}>
            Try again
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  const resetState = () => {
    setSessionId(undefined)
    setProgress(0)
    setActionState(undefined)
  }

  const { data: stats } = useGetImportSessionDataStats(
    apiEndpoint,
    sessionId,
    actionState === 'check-stats' || actionState === 'apply-changes',
  )

  const { data: importSession } = useGetImportSessionData(
    apiEndpoint,
    sessionId,
    actionState === 'check-ready-for-review',
  )

  const statsProgress = useMemo(
    () => (stats ? (stats.total - stats.state_pending) / stats.total : undefined),
    [stats],
  )

  if (statsProgress && progress !== statsProgress) {
    setProgress(statsProgress)
  }

  if (stats && stats.state_failure > 0) {
    resetState()
    showErrorsOccuredPopup()
  }

  if (actionState === 'apply-changes' && progress === 1) {
    resetState()
    refreshTableState()
    setOption(null)
    setPopupOpen(false)

    statusPopup.show(
      <StatusPopup variant="success">
        <StatusPopup.Title>Successfully assigned</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  if (
    actionState === 'check-ready-for-review' &&
    importSession?.state.id === 'ready_for_review'
  ) {
    setActionState('check-stats')
  }

  if (stats && sessionId && actionState === 'check-stats') {
    setActionState('apply-changes')

    if (stats.errors > 0) {
      resetState()
      showErrorsOccuredPopup()
    } else {
      applyImportSession(apiEndpoint, sessionId).catch(error => {
        resetState()

        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to assign</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
    }
  }

  const onAssign = async () => {
    const items = getSelectedItems()

    setBulkEditPending(true)

    try {
      const createImportSessionResponse = await createImportFromEntities(
        apiEndpoint,
        items,
        fieldsToBulkEdit,
      )
      const id = createImportSessionResponse.data.bulk_upload_id
      const sessionIdsResponse = await getImportSessionBulkItems(apiEndpoint, id)

      const bulkEditRespone = await bulkEditImportSessionRows(
        apiEndpoint,
        id,
        sessionIdsResponse.data.ids,
        {
          [field]: option?.name || null,
        },
      )

      if (bulkEditRespone?.data.edited === 0) {
        throw new Error('Nothing was selected')
      }

      setProgress(0)

      setSessionId(id)
      setActionState('check-ready-for-review')
      setBulkEditPending(false)
    } catch (error) {
      setBulkEditPending(false)
      resetState()

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to assign</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    }
  }

  return (
    <>
      <MoreBar.Action
        useIcon={buttonIcon}
        disabled={!someSelected}
        onClick={() => setPopupOpen(true)}
      >
        Change {visibleLabel}
      </MoreBar.Action>

      <Popup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        variant="bottom-sheet"
        preventUserClose={bulkEditPending || !!actionState}
      >
        <Header variant="bottom-sheet">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Assign {visibleLabel}</Header.Title>
        </Header>

        {actionState ? (
          <Cell>
            <VStack width="100%" align="center" space="s-8">
              <ProgressCircle strokeWidth={3} size={94} value={progress}>
                <ProgressCircle.Text>{formatPercentage(progress)}</ProgressCircle.Text>
              </ProgressCircle>

              <Text variant="primary">Assigning new {visibleLabel}</Text>
            </VStack>
          </Cell>
        ) : (
          <>
            <RadioSelectInput
              label={`Select ${visibleLabel}`}
              value={option}
              onChange={setOption}
              selector={selector}
            >
              {selector === selectorKeys.groups
                ? opt => <AccessGroupSelectorOption {...opt.value} />
                : undefined}
            </RadioSelectInput>

            <Popup.Actions>
              <Footnote>This will replace the {visibleLabel} already assigned</Footnote>
              <Button
                onClick={onAssign}
                elevated
                pending={bulkEditPending}
                disabled={!option}
              >
                Assign
              </Button>
            </Popup.Actions>
          </>
        )}
      </Popup>
    </>
  )
}
